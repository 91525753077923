import React, { useMemo } from "react";
import PropTypes from "prop-types";
import HamburgerDropdown from "../../component/UI/HamburgerDropdown";

const TableRow = (props) => {
  /**
   *
   */
  const onUpdate = (e, callback) => {
    e.preventDefault();

    callback(props.subscriptionPlan);

    return false;
  };

  /**
   *
   */

  const {
    name,
    price,
    price_annual,
    total_subscribers_all,
    total_subscribers_yearly,
    available,
    is_branded,
  } = props.subscriptionPlan;

  const { disabled } = props;

  const menuOptions = useMemo(() => {
    return [
      {
        label: "Edit",
        icon: "edit",
        onClick: (e) => onUpdate(e, props.onEdit),
      },
      {
        label: "Delete",
        icon: "delete",
        onClick: (e) => onUpdate(e, props.onDelete),
      },
    ];
  }, [props.onEdit, props.onDelete]);

  return (
    <tr>
      <td>{name}</td>
      <td>{is_branded ? <i className="material-icons">done</i> : null}</td>
      <td>{available ? <i className="material-icons">done</i> : null}</td>
      <td>{price}</td>
      <td>{price_annual}</td>
      <td>
        {total_subscribers_all - total_subscribers_yearly} /{" "}
        {total_subscribers_yearly}
      </td>

      <td className="text-right">
        <HamburgerDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  subscriptionPlan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    price_annual: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
  }).isRequired,

  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TableRow;
