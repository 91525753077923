/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

const initialState = {
  collection: [],

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  updateAlertUUID: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "ALERTS_FETCH":
      return {
        ...state,
        fetch: true,
      };

    case "ALERTS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "ALERTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case "ALERT_UPDATE":
      return {
        ...state,
        updateAlertUUID: action.payload.alertUUID,
      };

    case "ALERT_UPDATE_REJECTED":
      return {
        ...state,
        updateAlertUUID: "",
      };

    case "ALERT_UPDATE_FULFILLED":
      const alertIdx = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (alertIdx >= 0) {
        state.collection.splice(alertIdx, 1, action.payload);
      } else {
        state.collection.unshift(action.payload);
      }

      return {
        ...state,
        collection: state.collection,
        updateBarUUID: "",
      };

    case "ALERT_DELETE":
      return {
        ...state,
        updateBarUUID: action.payload.alertUUID,
      };

    case "ALERT_DELETE_FULFILLED":
      return {
        ...state,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
        updateBarUUID: "",
      };

    case "ALERT_DELETE_REJECTED":
      return {
        ...state,
        updateBarUUID: "",
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
