/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as userActions from "../../actions/user";

export function useUserStore() {
  const dispatch = useDispatch();
  const _user = useSelector((store) => store.user);

  const login = useCallback(
    async (email, password) =>
      await dispatch(userActions.login(email, password)),
    [dispatch]
  );

  const logout = useCallback(
    async () => await dispatch(userActions.logout()),
    [dispatch]
  );

  /**
   *
   * @type {function(*): Promise<void>}
   */
  const register = useCallback(
    /**
     *
     * @param {registerData }data
     * @returns {Promise<void>}
     */
    async (data) => await dispatch(userActions.register(data)),

    [dispatch]
  );

  /**
   *
   * @type {function(activeAccountData): Promise<void>}
   */
  const activateAccount = useCallback(
    /**
     * @param {activeAccountData} data
     * @type {function(*): Promise<void>}
     * */
    async (data) => await dispatch(userActions.activateAccount(data)),
    [dispatch]
  );

  const remindPassword = useCallback(
    async (email) => await dispatch(userActions.remindPassword(email)),
    [dispatch]
  );

  const resetPassword = useCallback(
    async (email, reset_token, password) =>
      await dispatch(userActions.resetPassword(email, reset_token, password)),
    [dispatch]
  );

  const editUser = useCallback(
    async (uuid, data, isAdminRequest) =>
      await dispatch(userActions.editUser(uuid, data, isAdminRequest)),
    [dispatch]
  );

  const checkSession = useCallback(
    async () => await dispatch(userActions.checkSession()),
    [dispatch]
  );

  const resendActivationToken = useCallback(
    async () => await dispatch(userActions.resendActivationToken()),
    [dispatch]
  );

  const changeOrganization = useCallback(
    async (orgUUID) => await dispatch(userActions.changeOrganization(orgUUID)),
    [dispatch]
  );

  const setUser = useCallback(
    async (data) => await dispatch(userActions.setUser(data)),
    [dispatch]
  );

  const setPluginLinkRedirect = useCallback(
    (url) => dispatch(userActions.setPluginLinkRedirect(url)),
    [dispatch]
  );

  return {
    user: _user,
    login,
    logout,
    register,
    remindPassword,
    resetPassword,
    editUser,
    checkSession,
    resendActivationToken,
    activateAccount,
    changeOrganization,
    setUser,
    setPluginLinkRedirect,
  };
}
