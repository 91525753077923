/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/02/2025
 */

import React, { useCallback, useEffect } from "react";
import appConfig from "../../../../../appConfig";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../../helpers/organization";
import { useUserStore } from "../../../../../hooks/redux/user";
import { Link } from "react-router-dom";

const ActionButtons = ({ isLoading }) => {
  const { currentOrganization } = useCurrentOrganizationStore();
  const { user, resendActivationToken, checkSession } = useUserStore();
  const userModel = user.model;
  const currentPlan = hasSubscription(currentOrganization);

  useEffect(() => {
    const onVisabilityChange = () => {
      console.log("check session here!", document.visibilityState);
      if (!currentPlan) {
        checkSession();
      }
    };
    window.addEventListener("visibilitychange", onVisabilityChange);

    return () => {
      window.removeEventListener("visibilitychange", onVisabilityChange);
    };
  }, [currentPlan]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onResentActivationLink = useCallback(
    (e) => {
      e.preventDefault();
      resendActivationToken();
    },
    [user.resendActivation]
  );

  const accountVerification = user.checkSession ? (
    <div>Verifying account...</div>
  ) : (
    <div>
      <div className={"text-danger"}>
        To continue, you must activate the account by clicking the link in the
        email we sent to <strong>{userModel.email}</strong>. <br />
      </div>
      <div className={"my-2"}>
        If you did not receive the email, please check your spam folder or click
        the button below to resend the activation link.
      </div>
      <div
        className={
          "d-flex align-items-center justify-content-end flex-wrap-reverse gap-xs"
        }
      >
        <Link
          to={"/"}
          className={"btn btn-link"}
          disabled={user.resendActivation}
        >
          Back to dashboard
        </Link>
        {user.resendActivationSuccess ? (
          <div className={"alert alert-success"}>
            Activation link has been resent to {userModel.email}.{" "}
            <strong>Follow the link and come back here to continue.</strong>
          </div>
        ) : (
          <button
            type={"button"}
            className={"btn btn-primary"}
            disabled={user.resendActivation}
            onClick={onResentActivationLink}
          >
            Resend activation link
          </button>
        )}
      </div>
    </div>
  );

  const content = !currentPlan ? (
    accountVerification
  ) : (
    <div className={"d-flex align-items-center justify-content-end gap-xs"}>
      <button
        type={"reset"}
        className={"btn btn-outline-primary"}
        disabled={isLoading}
      >
        Cancel
      </button>
      <button
        type={"submit"}
        className={"btn btn-primary"}
        disabled={isLoading}
      >
        Link with {appConfig.displayName}
      </button>
    </div>
  );

  return content;
};

export default ActionButtons;
