/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/10/2024
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import InstallAccordion from "../../InstallAccordion";
import appConfig from "../../../../../../appConfig";
import {
  headerTemplate as headerTemplateList,
  itemTemplate as itemTemplateList,
} from "./MailchimpInstructions/templates/listTemplate";
import {
  headerTemplate as headerTemplateCompact,
  itemTemplate as itemTemplateCompact,
} from "./MailchimpInstructions/templates/compactTemplate";
import Design, {
  getAllOptionValues,
  initBuilder,
} from "./MailchimpInstructions/Design";
import { MCT } from "../../../../../../consts";
import { definitions as MCDefinitions } from "../../../../../../builder/calendar/definitions/mailchimp";
import WidgetSettingsContext from "../../../../../../contexts/WidgetSettingsContext";
import { copyTextToClipboard } from "../../../../tools";
import {
  getRssFeedUrl,
  RSSFeedToObj,
} from "./MailchimpInstructions/templates/helpers";
import axios from "axios";
import useMailchimpTemplate from "./MailchimpInstructions/templates/useMailchimpTemplate";
import classNames from "classnames";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import useDidMountEffect from "../../../../../../hooks/useDidMountEffect";
import { useCalendarStore } from "../../../../../../hooks/redux/calendar";
import { useAppTagStore } from "../../../../../../hooks/redux/appTags";
import ExpandableAlert from "../../../../component/ExpandableAlert";
import NoEventsAlert from "../../../../pages/CalendarPage/NoEventsAlert";

const TemplateOptions = [
  {
    value: MCT.list,
    label: "Side by side",
  },
  { value: MCT.compact, label: "Stacked" },
];

const Templates = {
  [MCT.list]: {
    headerTemplate: headerTemplateList,
    itemTemplate: itemTemplateList,
  },
  [MCT.compact]: {
    headerTemplate: headerTemplateCompact,
    itemTemplate: itemTemplateCompact,
  },
};

const Tabs = [
  {
    label: "Mailchimp Template",
    id: "mc-template",
  },
  {
    label: "Preview",
    id: "mc-preview",
  },
];

const MailchimpInstructions = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onGoToAddWebsite,
}) => {
  const [currentTab, setCurrentTab] = useState(Tabs[1].id);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updatedEventsSettings, setUpdatedEventsSettings] = useState(null);
  const [state, setState] = useState({
    template: MCT.list,
  });
  const { calendar } = useCalendarStore();
  const { updateAppTag } = useAppTagStore();

  const [events, setEvents] = useState([]);
  const [copiedInfo, setCopiedInfo] = useState(false);
  const designSettings = useMemo(
    () => initBuilder(calendar.model, state.template, [MCDefinitions]),
    [state.template]
  );

  const MCSettings = useMemo(
    () => getAllOptionValues(state, designSettings),
    [state]
  );

  const { getPreview, getMailchimpSnippet } = useMailchimpTemplate(
    uuid,
    Templates[state.template].headerTemplate,
    Templates[state.template].itemTemplate,
    MCSettings,
    events,
    calendar.model
  );

  /**
   *
   * @type {(function(): Promise<void>)|*}
   */
  const pullRSSEvents = async () => {
    try {
      const result = await axios.get(
        getRssFeedUrl(uuid, MCSettings, calendar.model),
        {
          headers: {
            Accept: "text/xml, text/javascript, /; q=0.01",
            "X-Location-Path": window.location.pathname,
          },
        }
      );

      const d = document.createElement("div");
      d.innerHTML = result.data;
      setEvents(RSSFeedToObj(d));
    } catch (e) {
      console.error(e);
      const message =
        e?.message && e.message !== "Network Error"
          ? e.message
          : "Error fetching events feed";
      setError(message);
    }
  };

  const debouncedPullRssEvents = useDebounce(updatedEventsSettings, 1000);

  useEffect(() => {
    updateAppTag("user", "_mailchimp_builder_opened", false);
  }, []);

  useDidMountEffect(() => {
    setLoading(true);
    setError(false);

    setUpdatedEventsSettings(new Date().getTime());
  }, [
    MCSettings.startDate,
    MCSettings.daysLimit,
    MCSettings.eventsToShowOption,
    MCSettings.limitDescription,
    MCSettings.linkToThirdParty,
  ]);

  useEffect(() => {
    pullRSSEvents();
  }, [debouncedPullRssEvents]);

  useEffect(() => {
    if (copiedInfo) {
      const timer = setTimeout(() => {
        setCopiedInfo(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copiedInfo]);

  useDidMountEffect(() => {
    setLoading(false);
  }, [events]);

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onUpdateDesignProp = useCallback(
    (option) => (updatedFields) => {
      const data = { ...state };

      for (const key in updatedFields) {
        const updatedField = updatedFields[key];
        data[updatedField.field] = updatedField.value;
      }
      setState(data);
    },
    [state]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChange = useCallback(
    (field) => (e) => {
      const data = { ...state };

      if (e.target.type === "checkbox") {
        data[field] = e.target.checked;
      } else {
        data[field] = e.target.value;
      }
      setState(data);
    },
    [state]
  );

  const onTabClick = useCallback(
    (tabId) => (e) => {
      e.preventDefault();
      setCurrentTab(tabId);
    },
    [currentTab]
  );

  const templatesOptions = useMemo(() => {
    return TemplateOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }, []);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onCopyTemplate = useCallback(() => {
    copyTextToClipboard(getMailchimpSnippet());
    updateAppTag("user", "_mailchimp_template_copied", false);
    setCopiedInfo(true);
  }, [state]);

  const copyToClipboardButton = copiedInfo ? (
    <button className={"btn btn-link text-dark"} disabled={true}>
      Copied!
    </button>
  ) : (
    <button
      className={"btn btn-xs btn-outline-primary"}
      onClick={onCopyTemplate}
    >
      Copy template
    </button>
  );

  const tabs = useMemo(
    () =>
      Tabs.map((tab) => (
        <li key={tab.id} className="nav-item">
          <a
            className={classNames("nav-link", {
              active: currentTab === tab.id,
            })}
            onClick={onTabClick(tab.id)}
            href={`#${tab.id}`}
            data-toggle="tab"
          >
            {tab.label}
          </a>
        </li>
      )),
    [currentTab]
  );

  const loadingOverlay = loading ? (
    <div className={"install-modal--mc-preview--loading-overlay"}>LOADING</div>
  ) : null;

  const errorOverlay = loading ? (
    <div className={"install-modal--mc-preview--loading-overlay"}>{error}</div>
  ) : null;

  const eventsFilteringSummary = (
    <ExpandableAlert
      errorLevel={"info"}
      header={
        "This builder begins by displaying the events you chose in Setup and Design."
      }
      body={
        <span>
          This builder looks at your selections in the Setup tab→ Events
          filtering section AND the Design tab→ Events section→ Number of
          events. You will be able to make some adjustments during this builder
          process. To make major changes, navigate to Setup & Design, make your
          changes, and then return here.
        </span>
      }
    />
  );

  const noEventsAlert =
    !loading && events.length === 0 ? (
      <NoEventsAlert calendar={calendar} />
    ) : null;

  return (
    <WidgetSettingsContext.Provider value={MCSettings}>
      <div className={"install-modal--mc-preview"}>
        <InstallAccordion
          title={`Installing ${appConfig.displayName} on Mailchimp`}
          defaultExpanded={false}
        >
          <div className={"m-t-15"}>
            <div className={"alert alert-info"}>
              <strong>NOTE</strong> After copying the code snippet into
              Mailchimp, you need to enter PREVIEW mode to see how your events
              will look after sending.
            </div>
            <ol>
              <li>Open design editor in Mailchimp.</li>
              <li>
                Drag the CODE block and drop it where you'd like to display the
                events feed.
              </li>
              <li>Copy template code using the button below.</li>
              <li>Paste the copied code snippet into the CODE editor.</li>
              <li>Open preview to visually confirm all is good.</li>
            </ol>
          </div>
        </InstallAccordion>

        <div className={"container-fluid m-t-15"}>
          <div className={"row"}>
            <div className={"col-sm-7 col-md-9"}>
              <div
                className={
                  "d-flex align-items-center justify-content-between gap-xs"
                }
              >
                <ul className={"nav nav-tabs nav-tabs-bottom w-75"}>{tabs}</ul>
                {copyToClipboardButton}
              </div>
              <div
                className={
                  "d-100 d-flex justify-content-center m-t-15 position-relative"
                }
              >
                {loadingOverlay}
                {errorOverlay}
                {noEventsAlert}
                <iframe
                  className={"w-100"}
                  frameBorder={0}
                  srcDoc={
                    currentTab === Tabs[0].id
                      ? getMailchimpSnippet()
                      : getPreview()
                  }
                />
              </div>
            </div>
            <div
              className={
                "col-sm-5 col-md-3 border-left install-modal--scrollable-column"
              }
            >
              <div className={"m-t-5"}>{eventsFilteringSummary}</div>
              <label>Template</label>
              <select
                className={"form-control w-100"}
                value={state.template}
                onChange={onChange("template")}
              >
                {templatesOptions}
              </select>

              <Design
                template={MCT.list}
                onUpdateDesignProp={onUpdateDesignProp}
                widget={MCSettings}
                designSettings={designSettings}
              />
            </div>
          </div>
        </div>
      </div>
    </WidgetSettingsContext.Provider>
  );
};

export default MailchimpInstructions;
