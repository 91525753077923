import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import HamburgerDropdown from "../../component/UI/HamburgerDropdown";
import PublishDropdown from "../../component/UI/PublishDropdown";

const TableRow = (props) => {
  /**
   * Handles the update user event.
   *
   * @param {Function} callback - A callback function to be called when the user is updated.
   * @returns {boolean} - Always returns `false`.
   */
  const onUpdateUser = useCallback(
    (callback) => () => {
      callback(props.domain);

      return false;
    },
    [props.domain]
  );

  const { domain, available, disabled_date, uuid } = props.domain;

  const disabled = props.disabled;
  const disabledAlert = disabled_date ? (
    <div
      data-toggle="tooltip"
      data-placement="top"
      data-trigger={"hover"}
      title={"This website has been disabled due to plan downgrade."}
      className={"cursor-info"}
    >
      <i className={"design-tooltip text-danger material-icons"}>warning</i>
    </div>
  ) : null;

  const menuOptions = useMemo(
    () => [
      {
        label: "Edit",
        icon: "edit",
        href: `/domains/${uuid}`,
      },
      {
        label: "Delete",
        icon: "delete",
        onClick: onUpdateUser(props.onDelete),
      },
    ],
    [props.domain]
  );

  return (
    <tr>
      <td width={"20px"}>{disabledAlert}</td>
      <td>{domain}</td>
      <td>
        <PublishDropdown
          available={available}
          onChangeStatus={onUpdateUser(props.onChangeStatus)}
          disabled={disabled}
          customIcon={disabledAlert}
          activeLabel={"Active"}
          inactiveLabel={"Inactive"}
        />
      </td>

      <td className="text-right">
        <HamburgerDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

// PropTypes for props
TableRow.propTypes = {
  domain: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
    disabled_date: PropTypes.number,
  }).isRequired,
  disabled: PropTypes.bool,
  onChangeStatus: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

// Default props (optional)
TableRow.defaultProps = {
  disabled: false,
};

export default TableRow;
