/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/07/2021.
 */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SubscriptionAccess from "../../component/SubscriptionAccess";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import { Link } from "react-router-dom";
import moment from "moment";
import { IntegrationStatus } from "../../consts/integrations";
import classnames from "classnames";
import ResendActivationInfo from "../../templates/PageTemplate/ResendActivationInfo";
import IntegrationUser from "../../component/Integration/IntegrationUser";
import ApiError from "../../component/ApiError";
import PermissionsAlert from "./PermissionsAlert";
import ExpandableAlert from "../../component/ExpandableAlert";
import { useUserStore } from "../../../../hooks/redux/user";
import appConfig from "../../../../appConfig";
import PublishDropdown from "../../component/UI/PublishDropdown";
import HamburgerDropdown from "../../component/UI/HamburgerDropdown";
import HelpAnchor from "../../component/HelpAnchor";
import QueueJobProgress from "../../pages/QueueJobsPage/QueueJobProgress";
import QueueJobToDuration from "../../pages/QueueJobsPage/QueueJobToDuration";
import IntegrationCardSyncInProgress from "./IntegrationCardSyncInProgress";
import { INTEGRATION_AUTH_NAMES } from "../../../../consts";

const IntegrationCard = (props) => {
  const { integration, isLoading } = props;
  const { user } = useUserStore();

  /**
   *
   */
  const onAction = (callback) => (e) => {
    callback(props.integration);

    return false;
  };

  const hasActiveAccount = Number.parseInt(user.model.is_account_verified)
    ? true
    : false;

  const isDisabled = integration && integration.disabled_date;

  const startButton = props.available ? (
    !hasActiveAccount ? (
      <ResendActivationInfo />
    ) : (
      <SubscriptionAccess
        allowedPlans={["FREE", "BASIC", "STANDARD", "PREMIUM"]}
        CTAMessage={"Upgrade your account to sync with " + props.title}
      >
        <Link
          to={"integration/new/" + props.type}
          className="btn btn-outline-primary d-inline-flex align-items-center"
          onClick={props.onSelect}
        >
          Start integration{" "}
          <i className="material-icons" aria-hidden="true">
            arrow_forward
          </i>
        </Link>
      </SubscriptionAccess>
    )
  ) : (
    <h3 className="blog-title">Coming soon...</h3>
  );

  const lastSync =
    integration &&
    integration.last_sync &&
    integration.status !== IntegrationStatus.syncInProgress ? (
      <div>
        <span className={"text-secondary"}>Last sync: </span>
        {moment(integration.last_sync * 1000).format("Do MMM YYYY HH:mm")}
      </div>
    ) : null;

  const syncedAs =
    integration && integration.username ? (
      <div className={"m-b-5"}>
        <span className={"text-secondary"}>Syncing as: </span>
        <IntegrationUser integrationModel={integration} />
      </div>
    ) : null;

  const syncInProgress = (
    <IntegrationCardSyncInProgress integration={integration} />
  );

  const notSyncted =
    integration && integration.status === IntegrationStatus.notSynced ? (
      <div className={"d-flex align-items-center"}>
        <i className="material-icons icon-sm m-r-5" aria-hidden="true">
          link_off
        </i>
        Not synced yet
      </div>
    ) : null;

  const reAuthMessage = integration ? (
    <React.Fragment>
      <div className="alert alert-warning d-flex align-items-center gap-xxs">
        <i className={"material-icons icon-sm"}>warning</i> Session expired.
        <span>
          <a href={integration.auth_url}>
            Re-authenticate with {INTEGRATION_AUTH_NAMES[integration.type]}
          </a>
          .
        </span>
      </div>
    </React.Fragment>
  ) : null;

  const unfinishedOauthMessage = integration ? (
    <ExpandableAlert
      icon={"warning"}
      errorLevel={"danger"}
      header={
        <span>
          Setup incomplete.{" "}
          <Link to={"/integration/" + integration.uuid}>Finish setup.</Link>
        </span>
      }
      body={
        <span>
          For some reason, your integration setup was not completed
          successfully. Please return to the integration process and try again.{" "}
          <HelpAnchor
            href={`${appConfig.helpdeskAddress}/en/articles/5669866-dce-integrations-how-to-set-up`}
          >
            This article has 'how tos' for all our integrations.
          </HelpAnchor>
        </span>
      }
    />
  ) : null;

  const reAuthBtn =
    integration &&
    (integration.status === IntegrationStatus.reAuthRequired ||
      integration.state)
      ? integration.auth_type === "oauth" && !integration.username
        ? unfinishedOauthMessage
        : reAuthMessage
      : null;

  const syncError =
    integration &&
    integration.status === IntegrationStatus.syncError &&
    !integration.state ? (
      <ExpandableAlert
        icon={"warning"}
        errorLevel={"danger"}
        header={<span>Last sync failed</span>}
        body={<span>{integration.last_error_message}</span>}
      />
    ) : null;

  const permissionsError =
    integration &&
    integration.status === IntegrationStatus.permissionsRevoked &&
    !integration.state ? (
      <PermissionsAlert integration={integration} />
    ) : null;

  const disabledIntegrationAlert = isDisabled ? (
    <div className={"alert alert-danger d-flex align-items-center"}>
      <i className={"material-icons icon-sm text-danger m-r-5 "}>warning</i>
      This integration has been disabled due to plan downgrade.
    </div>
  ) : null;

  const autoSync = integration ? (
    <PublishDropdown
      onChangeStatus={onAction(props.onChangeStatus)}
      disabled={isLoading}
      available={integration.is_auto_sync}
      activeSummaryLabel={"Auto-sync is active"}
      inactiveSummaryLabel={"Auto-sync is inactive"}
      activeLabel={"Turn on"}
      inactiveLabel={"Turn off"}
    />
  ) : null;

  const loadingIndicator = props.isLoading ? (
    <LoadingIndicator className={"dce--progress"} />
  ) : null;

  const updateError = props.updateError ? (
    <ApiError error={props.updateError} defaultErrorMessage={""} />
  ) : null;

  const syncDisabled =
    !integration ||
    [
      IntegrationStatus.syncInProgress,
      IntegrationStatus.reAuthRequired,
      IntegrationStatus.permissionsRevoked,
    ].includes(integration.status);

  const dropdownActions = useMemo(() => {
    const options = [];

    if (integration) {
      if (!integration.disabled_date) {
        options.push({
          label: "Quick Sync (Recommended)",
          onClick: onAction(props.onQuickSync),
          icon: "sync",
          disabled: syncDisabled,
        });
      }

      options.push({
        label: "Edit",
        title: "Edit",
        href: "/integration/" + integration.uuid,
        icon: "edit",
      });

      if (!integration.disabled_date) {
        options.push({
          label: (
            <React.Fragment>
              Full Re-Sync (<span className={"text-warning"}>Caution</span>)
            </React.Fragment>
          ),
          title: "Full Re-sync (Caution)",
          onClick: onAction(props.onFullReSync),
          icon: "refresh",
          disabled: syncDisabled,
        });
      }

      options.push({
        label: "Delete",
        title: "Delete",
        onClick: onAction(props.onDelete),
        icon: "delete",
      });
    }

    return options;
  }, [integration, props.onDelete, props.onFullReSync, props.onQuickSync]);

  const actions = integration ? (
    <HamburgerDropdown disabled={isLoading} options={dropdownActions} />
  ) : null;

  const openAI =
    integration &&
    integration.settings &&
    integration.settings.create_embeddings ? (
      <div>
        {/*<img
        src={"/img/integrations_icons/openai.svg"}
        width={"16"}
        height={"16"}
        alt={"OpenAI Integration enabled"}
        title={"OpenAI Integration enabled"}
        className={"img-responsive"}
      />{" "}*/}
        <span className={"text-secondary"}>OpenAI: </span>
        Sync is active
      </div>
    ) : null;

  const details = integration ? (
    <div className={""}>
      <div className={"integration-card--details-info"}>
        {syncedAs}
        {lastSync}
        {syncInProgress}
        {notSyncted}
        {openAI}
        {loadingIndicator}
      </div>
    </div>
  ) : (
    <div className={"blog-info clearfix"}>{startButton}</div>
  );

  const beta = props.beta ? (
    <React.Fragment>
      <div
        className={"badge badge-primary design-tooltip"}
        data-toggle="tooltip"
        data-placement="bottom"
        title={
          props.premium
            ? "It is an integration which is being tested. Currently, it is free to use. Once the trial period is finished, you will have two options: continue using it as a premium integration (charged) or opt out. NOTE: You will never be charged automatically for beta testing."
            : "It is an integration which is being tested. It is free to use"
        }
      >
        BETA
      </div>{" "}
    </React.Fragment>
  ) : null;

  const premium = props.premium ? (
    <React.Fragment>
      <div
        className={"badge design-tooltip integration-card--premium m-l-15"}
        data-toggle="tooltip"
        data-placement="top"
        title={"Paid Integration"}
      >
        <img src={"/img/crown.png"} alt={"Paid integration"} />
      </div>{" "}
    </React.Fragment>
  ) : null;

  const description = !integration ? (
    <React.Fragment>
      <p>{props.description}</p>
      {props.help ? props.help : null}
    </React.Fragment>
  ) : null;

  return (
    <div
      className={classnames("col-sm-6 col-md-6 col-lg-6 integration-card", {
        "integration-card--active":
          integration && Number.parseInt(integration.available) ? true : false,
      })}
    >
      <div className="blog grid-blog ">
        <div>
          <div className="blog-content ">
            <div
              className={
                "d-flex justify-content-between align-items-center m-b-15"
              }
            >
              <div className={"d-flex align-items-center"}>
                <img
                  className="integration-card--icon m-r-5"
                  src={`/img/integrations_icons/${props.icon}`}
                  alt={props.title}
                />
                <h3 className="blog-title">{props.title}</h3>
              </div>
              <div className={"d-flex align-items-center"}>
                {beta}
                {premium}
                {actions}
              </div>
            </div>
            {description}
          </div>
        </div>
        {details}
        {disabledIntegrationAlert}
        <hr />
        {autoSync}
        {reAuthBtn}
        {syncError}
        {permissionsError}
        {updateError}
      </div>
    </div>
  );
};

IntegrationCard.defaultProps = {
  customPostDescription: null,
};

IntegrationCard.propTypes = {
  available: PropTypes.bool,
  beta: PropTypes.bool,
  premium: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  integration: PropTypes.object,
  help: PropTypes.object,
  onChangeStatus: PropTypes.func.isRequired,
  onQuickSync: PropTypes.func.isRequired,
  onFullReSync: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateError: PropTypes.object,
};

export default IntegrationCard;
