/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

const initialState = {
  collection: [],
  pagination: {},

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  updateWidgetUUID: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "WIDGETS_FETCH":
      return {
        ...state,
        fetch: true,
      };

    case "WIDGETS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload.widgets,
        pagination: action.payload.pagination,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "WIDGETS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case "WIDGET_UPDATE":
      return {
        ...state,
        updateWidgetUUID: action.payload.widgetUUID,
      };

    case "WIDGET_UPDATE_REJECTED":
      return {
        ...state,
        updateWidgetUUID: "",
      };

    case "WIDGET_UPDATE_FULFILLED":
      const widgetIdx = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (widgetIdx >= 0) {
        state.collection.splice(widgetIdx, 1, action.payload);
      } else {
        state.collection.unshift(action.payload);
      }

      return {
        ...state,
        collection: state.collection,
        updateWidgetUUID: "",
      };

    case "WIDGET_DELETE":
      return {
        ...state,
        updateWidgetUUID: action.payload.widgetUUID,
      };

    case "WIDGET_DELETE_FULFILLED":
      return {
        ...state,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
        updateWidgetUUID: "",
      };

    case "WIDGET_DELETE_REJECTED":
      return {
        ...state,
        updateWidgetUUID: "",
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
