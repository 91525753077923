/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */

import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CancelStep1 from "./CancelStep1";
import CancelStep2 from "./CancelStep2";
import CancelStep3 from "./CancelStep3";
import ApiError from "../../component/ApiError";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import { useSubscriptionStore } from "../../../../hooks/redux/subscription";
import { useUserStore } from "../../../../hooks/redux/user";
import {useCurrentOrganizationStore} from "../../../../hooks/redux/currentOrganization";

const Steps = {
  step1: 0,
  step2: 1,
  step3: 2,
};

const StepsMapping = {
  [Steps.step1]: CancelStep1,
  [Steps.step2]: CancelStep2,
  [Steps.step3]: CancelStep3,
};

const StepToValueMapping = {
  [Steps.step1]: "reason",
  [Steps.step2]: "comment",
  [Steps.step3]: "password",
};

const Form = (props) => {
  const [currentStep, setCurrentStep] = useState(Steps.step1);
  const StepContent = StepsMapping[currentStep];
  const { user } = useUserStore();
  const { subscription, terminateSubscription } = useSubscriptionStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const [values, setValues] = useState({
    reason: "0",
    comment: "",
    password: "",
  });

  const loadingIndicator = subscription.delete ? <LoadingIndicator /> : null;

  const canCancel = useMemo(() => {
    if (!subscription.delete) {
      if (user.model.is_google_account && values.password === "I CONFIRM") {
        return true;
      } else if (values.password.length >= 6) {
        return true;
      }
    }

    return false;
  }, [values, subscription.delete]);

  /**
   *
   * @type {function(*, *): function(*): void}
   */
  const setFeedbackValue = useCallback(
    (field, increaseStep) => (e) => {
      const newState = { ...values };

      newState[field] = e.target.value;

      setValues(newState);

      if (increaseStep) {
        setCurrentStep(currentStep + 1);
      }
    },
    [values, setValues, currentStep]
  );

  /**
   *
   * @param e
   * @returns {*|boolean}
   */
  const onSubmit = (e) => {
    e.preventDefault();

    terminateSubscription(
      user.model.email,
      values.password,
      values.reason,
      values.comment,
      user.model.is_google_account,
      currentOrganization.model.uuid
    );

    return false;
  };

  const increaseStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };

  const finalStepButton =
    currentStep === Steps.step2 ? (
      <button
        className="btn btn-primary btn-lg"
        onClick={increaseStep}
        disabled={
          Number.parseInt(values.reason) === 7 && values.comment.length <= 3
        }
      >
        Final Step
      </button>
    ) : null;

  const confirmCancelButton =
    currentStep === Steps.step3 ? (
      <div className={"d-flex gap-xs flex-column w-100 align-items-center"}>
      <button
        className="btn btn-primary btn-lg btn-danger"
        onClick={onSubmit}
        disabled={!canCancel}
      >
        Cancel subscription
      </button>
      <ApiError
        error={subscription.deleteError}
        defaultErrorMessage={
          "Could not cancel the subscription. Please try again"
        }
      />
      </div>
    ) : null;

  const footer =
    currentStep === Steps.step3 || currentStep === Steps.step2 ? (
      <div className={"card-footer d-flex justify-content-end"}>
        {finalStepButton}
        {confirmCancelButton}
        {loadingIndicator}

      </div>
    ) : null;

  return (
    <div className="card">
      <StepContent
        onChange={setFeedbackValue}
        values={values}
        disabled={subscription.delete}
      />
      {footer}
    </div>
  );
};

Form.defaultProps = {};

Form.propTypes = {};

export default Form;
