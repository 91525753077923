/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */
import { PT } from "../../../consts";

const initialState = {
  mobileNavOpen: false,
  sidebarMini: false,
  pathname: "/",
  previousPathname: null,

  previewType: PT.auto,
  isWidgetPreviewMode: false,

  showAdminNav: true,

  widgetSettingsUpdateTimestamp: 0,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      if (!window.__DCE__DEV__) {
        window.Intercom("update");
      }

      return {
        ...state,
        previousPathname: state.pathname,
        pathname: action.payload.pathname,
      };

    case "TOGGLE_MOBILE_NAV":
      return {
        ...state,
        mobileNavOpen: action.payload.mobileNavOpen,
      };

    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        sidebarMini: action.payload.sidebarMini,
      };

    case "WIDGET_PREVIEW_MODE_CHANGE":
      return {
        ...state,
        previewType: action.previewType,
      };

    case "WIDGET_PREVIEW_TOGGLE":
      return {
        ...state,
        isWidgetPreviewMode: action.isWidgetPreviewMode,
      };

    case "ADMIN_NAVIGATION_TOGGLE":
      return {
        ...state,
        showAdminNav: !state.showAdminNav,
      };

    case "WIDGET_DESIGN_PROP_UPDATE":
      return {
        ...state,
        widgetSettingsUpdateTimestamp: new Date().getTime(),
      };
    case "LOGOUT":
      return initialState;

    default:
      return state;
  }

  return state;
}
