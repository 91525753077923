/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2025
 */

import React from "react";
import appConfig from "../../../../appConfig";
import { useUserStore } from "../../../../hooks/redux/user";
import { ucFirst } from "../../../../helpers/string";

const CompleteWPConnection = () => {
  const {
    user: { pluginRedirectUrl },
  } = useUserStore();

  if (!pluginRedirectUrl) {
    return null;
  }

  // read site name value from redirectUrl
  const siteName = pluginRedirectUrl.split("sitename=")[1].split("&")[0];

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="card-box">
          <h4 className="card-title">Complete WordPress Connection Setup</h4>
          <p>
            To finish connecting your <strong>{ucFirst(siteName)}</strong>{" "}
            WordPress site with {appConfig.displayName}, click the button below.
            You’ll be redirected to complete the linking process. Once done,
            you’ll return to your WordPress dashboard, and the connection will
            be fully set up.
          </p>
          <a
            href={pluginRedirectUrl}
            className="btn btn-primary"
            target={"_finishWPSetup"}
          >
            Complete Setup
          </a>
        </div>
      </div>
    </div>
  );
};

export default CompleteWPConnection;
