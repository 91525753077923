import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { formatter } from "../../tools";
import HamburgerDropdown from "../../component/UI/HamburgerDropdown";

const TableRow = (props) => {
  /**
   *
   */
  const onUpdate = (e, callback) => {
    e.preventDefault();

    callback(props.license);

    return false;
  };

  /**
   *
   */

  const {
    uuid,
    created,
    assigned_organization,
    license_key,
    is_private,
    cost,
  } = props.license;

  const disabled = props.disabled;

  const menuOptions = useMemo(
    () => [
      {
        label: "Edit",
        icon: "edit",
        onClick: (e) => onUpdate(e, props.onEdit),
      },
      {
        label: "Delete",
        icon: "delete",
        onClick: (e) => onUpdate(e, props.onDelete),
      },
    ],
    [props.onEdit, props.onDelete]
  );

  return (
    <tr>
      <td>{moment(created * 1000).format("YYYY-MM-DD")}</td>
      <td>{formatter.format(cost)}</td>
      <td>{assigned_organization ? assigned_organization.name : "-"}</td>
      <td>{license_key}</td>

      <td className="text-right">
        <HamburgerDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  license: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    created: PropTypes.number.isRequired,
    assigned_user: PropTypes.object,
    is_private: PropTypes.number.isRequired,
    license_key: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
  }).isRequired,

  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TableRow;
