/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/10/2018.
 */

const initialState = {
  collection: [],

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  updateCalendarUUID: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDARS_FETCH":
      return {
        ...state,
        fetch: true,
      };

    case "CALENDARS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "CALENDARS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case "CALENDAR_UPDATE":
      return {
        ...state,
        updateCalendarUUID: action.payload.calendarUUID,
      };

    case "CALENDAR_UPDATE_REJECTED":
      return {
        ...state,
        updateCalendarUUID: "",
      };

    case "CALENDAR_UPDATE_FULFILLED":
      const calendarIdx = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (calendarIdx >= 0) {
        const oldItem = state.collection[calendarIdx];
        action.payload.modified = oldItem.modified;
        state.collection.splice(calendarIdx, 1, action.payload);
      } else {
        state.collection.unshift(action.payload);
      }

      return {
        ...state,
        collection: [...state.collection],
        updateCalendarUUID: "",
      };

    case "CALENDAR_DELETE":
      return {
        ...state,
        updateCalendarUUID: action.payload.calendarUUID,
      };

    case "CALENDAR_DELETE_FULFILLED":
      return {
        ...state,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
        updateCalendarUUID: "",
      };

    case "CALENDAR_DELETE_REJECTED":
      return {
        ...state,
        updateCalendarUUID: "",
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
