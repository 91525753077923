/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2024
 */

import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

/**
 *
 * @returns {Element}
 * @constructor
 */
const SubscribeButton = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Link
        to={`${location.pathname}${location.search}#add-to-calendar`}
        className={"share-event share-event--link"}
        // className={"dc-calendar-share-button"}
      >
        <i className={"material-icons"}>calendar_today</i>
        {t("Add to calendar")}
      </Link>
      {/*{subscribePopup}*/}
    </>
  );
};

export default SubscribeButton;
