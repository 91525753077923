/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import SelectorGroup from "./Selectors/SelectorGroup";
import MonthSelector from "./CalendarHeader/MonthSelector";
import CalendarHeaderLabel from "./CalendarHeader/CalendarHeaderLabel";
import IntegrationContext from "../strategies/IntegrationContext";
import { useFiltersStore } from "../../../hooks/redux/filters";
import LoadingIndicator from "./LoadingIndicator";
import CalendarHeaderSelectors from "./CalendarHeaderSelectors";
import WidgetFilterLabels from "./CalendarHeader/WidgetFilterLabels";
import useHeaderFilters from "../../../hooks/useHeaderFilters";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";
import ShareCalendarButton from "./ShareCalendarButton";
import FeatureFlag from "../../admin/component/FeatureFlag";
import appConfig from "../../../appConfig";
import SearchBar from "./SearchBar";
import { calendarPublicName } from "../helpers";
import SubscribeCalendarButton from "./SubscribeCalendarButton";

const CalendarHeader = ({
  currentDate,
  events,
  calendar,
  showMonthSelector = true,
  onUpcomingNavigationChange,
  selectedFilters,
  onChangeDate,
  onChangeFilter,
  onSearch,
}) => {
  const [state, setState] = useState({
    showFilters: false,
  });

  const { fetchCalendarFilters, filters } = useFiltersStore();

  const widgetSettings = useContext(WidgetSettingsContext);
  const integrationStrategy = useContext(IntegrationContext);

  /**
   *
   */
  const toggleFilters = useCallback(() => {
    setState({ showFilters: !state.showFilters });
  }, [state.showFilters]);

  useHeaderFilters(
    integrationStrategy,
    widgetSettings,
    fetchCalendarFilters,
    calendar.model.uuid,
    state.showFilters,
    [integrationStrategy.availableFilters, state.showFilters]
  );

  const showMonthHeader = widgetSettings["showMonthHeader"] === 1;

  const kindOfNumberOfEvents = widgetSettings["eventsNumberOption"] || "all";

  const showPaginationNavigation =
    widgetSettings["showPaginationNavigation"] === 1;

  const showNavigation =
    ["upcoming", "all"].indexOf(kindOfNumberOfEvents) >= 0 &&
    showPaginationNavigation;

  const showUpcomingNavigationValue =
    widgetSettings["showUpcomingNavigation"] === 1;

  const showUpcomingNavigation =
    showUpcomingNavigationValue && kindOfNumberOfEvents == "upcoming-limit";

  const navigation = showNavigation ? (
    <MonthSelector onChangeDate={onChangeDate} />
  ) : null;

  const upcomingNavigation = showUpcomingNavigation ? (
    <MonthSelector onChangeDate={onUpcomingNavigationChange} />
  ) : null;

  const hasSelectors = useMemo(
    () =>
      integrationStrategy.availableFilters
        .map((kind) => {
          const showSelector = widgetSettings[`show${kind}Dropdown`];

          if (showSelector) {
            return 1;
          }
          return 0;
        })
        .reduce((a, b) => a + b, 0) > 0,
    [integrationStrategy.availableFilters, widgetSettings]
  );

  const expandFilters = widgetSettings["filterExpanded"] === 1;
  const showFilterToggle = widgetSettings["filterToggleShow"] === 1;

  useEffect(() => {
    setState({ showFilters: expandFilters && hasSelectors });
  }, [expandFilters, hasSelectors]);

  const showFilterLabel =
    hasSelectors && widgetSettings["showFiltersLabel"] === 1;
  const filterLabelText =
    hasSelectors && showFilterLabel ? widgetSettings["headerFiltersLabel"] : "";

  /**
   * If it is a single event, means that we cannot display selected date which is current date by default.
   * It may cause a wrong month header label display. E.g. Event happens in July and current month is June.
   * This way it will show June until events are loaded. If it's a single event, wait for that event to load
   * before displaying header.
   * @type {JSX.Element|null}
   */
  const monthSelector = showMonthSelector ? (
    <div className="month-selector">
      <CalendarHeaderLabel
        currentDate={currentDate}
        show={showMonthHeader}
        events={events}
        eventsNumberOption={kindOfNumberOfEvents}
        showUpcomingNavigation={showUpcomingNavigation}
      />

      {navigation}
      {upcomingNavigation}
    </div>
  ) : null;

  const filterLabel = showFilterLabel ? (
    <div className={classnames("filter_title")}>{filterLabelText}</div>
  ) : null;

  const filtersToggleButton =
    showFilterToggle && hasSelectors ? (
      <>
        <div
          className={classnames("month-selector--filters-button", {
            active: state.showFilters || expandFilters,
          })}
          onClick={toggleFilters}
        >
          <i className="material-icons">filter_list</i>
        </div>
        {filterLabel}
      </>
    ) : null;

  const loadingIndicator = filters.fetchCalendarFilters ? (
    <div className={"loading-indicator"}>
      <LoadingIndicator />
    </div>
  ) : null;

  const selectors =
    state.showFilters && !loadingIndicator ? (
      <CalendarHeaderSelectors
        onChangeFilter={onChangeFilter}
        selectedFilters={selectedFilters}
      />
    ) : null;

  return (
    <div>
      <WidgetFilterLabels calendar={calendar} />

      <div className={"dc-calendar--filter-share-container"}>
        <div className={"month-selector--filters-controls"}>
          {filtersToggleButton}
          <SearchBar onSearch={onSearch} />
        </div>

        <div className={"dc-calendar--header-features-container"}>
          <SubscribeCalendarButton
            title={calendarPublicName(calendar.model)}
            url={`${process.env.BASE_URL}/c/${calendar.model.slug}`}
            RSSUrl={`${process.env.BASE_URL}/feeds/calendar/${calendar.model.uuid}.rss`}
            iCalPath={`${calendar.model.integration.uuid}/${calendar.model.uuid}`}
          />
          <ShareCalendarButton
            title={calendarPublicName(calendar.model)}
            url={`${process.env.BASE_URL}/c/${calendar.model.slug}`}
          />
        </div>
      </div>

      {monthSelector}
      {""}
      <SelectorGroup show={state.showFilters}>
        {loadingIndicator}
        {selectors}
      </SelectorGroup>
    </div>
  );
};

CalendarHeader.propTypes = {
  onChangeFilter: PropTypes.func,
  selectedFilters: PropTypes.object,

  onUpcomingNavigationChange: PropTypes.func,
  showMonthSelector: PropTypes.bool,
  calendar: PropTypes.object,
  events: PropTypes.array,
  currentDate: PropTypes.object,

  onSearch: PropTypes.func,
};

export default CalendarHeader;
