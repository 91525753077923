/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/01/2025.
 */

import { useMemo } from "react";

export const useSortableTable = (data, columns, sortBy, sortDirection) => {
  const sortedData = useMemo(() => {
    const column = sortBy
      ? columns.find((column) => column.id === sortBy)
      : null;

    if (!column || !column.sort || !sortDirection) {
      return data;
    }

    return [...data].sort(column.sort(sortDirection));
  }, [data, sortBy, sortDirection]);

  return { sortedData, /*onSort,*/ sortBy, sortDirection };
};
