import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { getPlanSubscriptionName } from "../../../../helpers/subscription";
import HamburgerDropdown from "../../component/UI/HamburgerDropdown";

const GoogleAccountIcon = () => (
  <img
    src={"/img/google_account.png"}
    alt={"Account created with Google Signup"}
    className={"img-responsive m-r-5 "}
    width={24}
  />
);

const AdminAccountIcon = () => (
  <img
    src={"/img/admin.svg"}
    alt={"Admin account"}
    className={"img-responsive m-r-5 "}
    width={24}
  />
);

const TableRow = (props) => {
  /**
   *
   * @returns {function(*, *): boolean}
   */
  const onUpdateUser = (callback) => (e) => {
    e.preventDefault();

    callback(props.user);

    return false;
  };

  /**
   *
   * @param user
   */
  const onShowDetails = (user) => (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    props.onShowEditModal(user);
    return false;
  };

  const {
    uuid,
    name,
    email,
    created,
    role,
    is_account_verified,
    is_google_account,
  } = props.user;

  const disabled = props.disabled;

  const verified =
    is_account_verified * 1 ? <i className="material-icons">done</i> : null;

  /*const feePaid =
    Number.parseInt(installation_fee_paid) > 0
      ? moment(installation_fee_paid * 1000).format("YYYY-MM-DD")
      : null;
*/
  /*const currentPlan = users_plans && users_plans.length ? users_plans[0] : null;

  const plan = getPlanSubscriptionName(currentPlan);
  const planExpiry =
    currentPlan && currentPlan.expiry != null
      ? moment(currentPlan.expiry * 1000).format("YYYY-MM-DD")
      : null;
*/
  let rowBgClass = "";

  /**
   * plan expiration period (available == 1 && expiry > now())
   * plan expired (available 0)
   * plan terminated (is_terminated == 1)
   * plan trial (trial = 1)
   * plan trial expiration period (trial = 1...)
   */

  /*if (plan) {
    if (Number.parseInt(currentPlan.available) === 1) {
      // plan expired yet still available (due to cancel for unpaid subscription)

      if (Number.parseInt(currentPlan.is_trial) === 1) {
        rowBgClass = "table-info";
      }

      if (Number.parseInt(currentPlan.expiry) < moment().unix()) {
        rowBgClass = "table-secondary";
      } else {
        rowBgClass = "table-success";
      }

      if (Number.parseInt(currentPlan.is_terminated) === 1) {
        rowBgClass = "table-warning";
      }
    } else {
      if (Number.parseInt(currentPlan.is_terminated) === 1) {
        rowBgClass = "table-danger";
      }
    }
  }*/

  /*const hasBrandedPlan =
    currentPlan && currentPlan.subscription_plan.is_branded * 1;

  const treasurerEmail =
    treasurer_email !== email ? (
      <div className={""}>
        <small>({treasurer_email})</small>
      </div>
    ) : null;*/

  const menuOptions = useMemo(() => {
    return [
      {
        icon: "assignment_ind",
        label: "Details",
        onClick: onUpdateUser(onShowDetails(props.user)),
      },
      {
        icon: "login",
        label: "Auto login",
        onClick: onUpdateUser(props.onAutoLogin),
      },
      {
        icon: "delete",
        label: "Delete",
        onClick: onUpdateUser(props.onDelete),
      },
    ];
  }, [props.user]);

  return (
    <tr className={rowBgClass}>
      <td>
        {is_google_account ? <GoogleAccountIcon /> : null}
        {role === "admins" ? <AdminAccountIcon /> : null}
        {name}
      </td>
      <td>{email}</td>
      <td>{verified}</td>
      <td>{moment(created * 1000).format("YYYY-MM-DD HH:mm")}</td>

      <td className="text-right">
        <HamburgerDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    created: PropTypes.number.isRequired,
    is_verified: PropTypes.number,
    is_google_account: PropTypes.number,
  }),

  onAutoLogin: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onTerminateSubscription: PropTypes.func.isRequired,
};

export default TableRow;
