import {
  getOriginEventPageUrl,
  getSharedEventUrl,
  removeEventUrlQueryParams,
} from "../../../helpers/calendar";
import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { getWidgetItemSlugFromUrl } from "../../../helpers/linking";
import useQuery from "../../../hooks/useQuery";

export const useEventLinking = (
  calendarUUID,
  selectedEvent,
  widgetSettings,
  highlightsSettings = null
) => {
  const history = useHistory();
  const selectedEventSlug = selectedEvent?.slug;
  const [originEventsSlug, setOriginEventsSlug] = useState(
    getWidgetItemSlugFromUrl("event")
  );
  const query = useQuery();
  const isHighlight = query.has("highlights");

  const openChurchCenter =
    Number.parseInt(widgetSettings["generalGoToChurchCenter"]) === 1;

  const primaryCtaAction = widgetSettings[`cCardCTAAction1st`];

  const triggerCTAOnCardClick =
    Number.parseInt(widgetSettings["cCardActionOnClick"]) === 1;

  const openLinkInNewTab =
    Number.parseInt(widgetSettings["openLinksNewTab"]) === 1;

  // const showDetailsAsModal =
  //   Number.parseInt(widgetSettings["generalOpenDetailsModal"]) === 1;

  const expandDetailsAsAccordionValue =
    widgetSettings["generalExpandDetailAccording"] && !originEventsSlug;

  const showDetailsAsModalValue =
    widgetSettings["generalOpenDetailsModal"] || originEventsSlug;

  const showHighlightsDetailsAsModalValue = highlightsSettings
    ? highlightsSettings["generalOpenDetailsModal"]
    : false;

  const expandDetailsAsAccordion = expandDetailsAsAccordionValue ? true : false;

  const showDetailsAsModal = showDetailsAsModalValue ? true : false;

  /**
   *
   * @param _event
   * @returns {*|`/${*}/event/${string}`}
   */
  const getEventUrl = (_event) => {
    const primaryCtaLink = _event?.ctas[primaryCtaAction] || null;

    if (triggerCTAOnCardClick && primaryCtaLink) {
      return primaryCtaLink.url;
    } else if (openChurchCenter) {
      return getOriginEventPageUrl(_event);
    }
    return getSharedEventUrl(calendarUUID, _event.slug, _event.name);
  };

  /**
   *
   * @type {function(*): function(*): (undefined|boolean)}
   */
  const onClickEvent = useCallback(
    (_event, params = {}) =>
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        const sourceEvent = selectedEvent || _event;
        const primaryCtaLink = sourceEvent?.ctas[primaryCtaAction] || null;

        if (triggerCTAOnCardClick && primaryCtaLink) {
          // open primaryCtaLink.url link in new tab if openLinkInNewTab is true
          window.open(
            primaryCtaLink.url,
            openLinkInNewTab ? sourceEvent.slug : "_self"
          );
          return;
        }

        if (openChurchCenter) {
          window.open(
            getOriginEventPageUrl(sourceEvent),
            openLinkInNewTab ? sourceEvent.slug : "_self"
          );
          return false;
        } else {
          if (showDetailsAsModal || expandDetailsAsAccordion) {
            const _params = {
              ...params,
            };

            if (e.target.closest(".dc-highlights_container")) {
              _params["highlights"] = "1";
            }

            history.push(
              getSharedEventUrl(
                calendarUUID,
                sourceEvent.slug,
                sourceEvent.name,
                _params
              )
            );
          }
        }
        // props.onSelectedEvent(event);
        return false;
      },
    [
      selectedEvent,
      openChurchCenter,
      openLinkInNewTab,
      history,
      calendarUUID,
      showDetailsAsModal,
      triggerCTAOnCardClick,
    ]
  );

  const onCloseOpenedEventsDetails = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      // setSelectedEvent(null);
      // setOriginEventsSlug(null);
      history.push(
        removeEventUrlQueryParams([
          "calendar",
          "event",
          "selector",
          "highlights",
        ])
      );
    },
    [selectedEvent]
  );

  return {
    onClickEvent,
    selectedEventSlug,
    selectedEvent,
    getEventUrl,
    onCloseOpenedEventsDetails,
    originEventsSlug,
    showDetailsAsModal,
    expandDetailsAsAccordion,
    showHighlightsDetailsAsModalValue,
    isHighlight,
  };
};

export default useEventLinking;
