/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/06/2022
 */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const BreadcrumbBar = (props) => {
  const icon = props.icon ? (
    <div
      className={classnames(
        "breadcrumb-bar--icon",
        `bg-nav-${props.sidebarId}`
      )}
    >
      <i className={"material-icons"} aria-hidden="true">
        {props.icon}
      </i>{" "}
    </div>
  ) : null;

  const iconImg = props.iconImg || null;

  return (
    <div className={`breadcrumb-bar`}>
      <h3 className={"d-flex align-items-center"}>
        {icon} {iconImg} {props.title}
      </h3>
      <div className={"breadcrumb-bar--controls"}> {props.controls}</div>
    </div>
  );
};

BreadcrumbBar.defaultProps = {};

BreadcrumbBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  controls: PropTypes.array,
};

export default BreadcrumbBar;
