/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022.
 */

import { GT, IT } from "../../../consts";

export const definitions = {
  title: "Outline styles",
  icon: "brush",
  groups: [
    {
      label: "Main information",
      options: [
        {
          title: "Group name color",
          templates: [GT.outlined],
          name: "gCGroupName",
          defaultValue: "#fff",
          style: {
            selector: ["h2.dce--outlined-header"],
            property: "-webkit-text-stroke-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of group name.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Group name color on hover",
          templates: [GT.outlined],
          name: "gCHGroupName",
          defaultValue: "#ed8f15",
          style: {
            selector: ["h2.dce--outlined-header:hover"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "The color of the group name when you hover on it.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Group description color",
          templates: [GT.outlined],
          name: "gODescGroupName",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce--outlined-card .dce--outlined-card_container-description p.line-clamp",
              ".dce--outlined-card .dce--outlined-card_container-description p>*",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message:
              "If hover effect is flip, color of group description when flipped.",
          },
          show: [
            {
              field: "gOHoverEffect",
              value: "dce--outlined-card_effect-flip",
              defaultValue: "dce--outlined-card_effect-expand",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Read more link color",
          templates: [GT.outlined],
          name: "gOReadMore",
          defaultValue: "#a09e9e",
          style: {
            selector: [
              ".dce--outlined-card .dce--outlined-card_container-description .dce--outlined-card_container-read-more",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message:
              "If hover effect is flip, color of read more link when flipped.",
          },
          show: [
            {
              field: "gOHoverEffect",
              value: "dce--outlined-card_effect-flip",
              defaultValue: "dce--outlined-card_effect-expand",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Images",
      options: [
        {
          title: "Image blend",
          templates: [GT.outlined],
          name: "gBCImageBlend",
          defaultValue: "#123648",
          style: {
            selector: [".dce--outlined-card .blend"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Sets a colored overlay on your group image.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Image blend on hover",
          templates: [GT.outlined],
          name: "gBCHImageBlend",
          defaultValue: "#0092F8",
          style: {
            selector: [".dce--outlined-filter_azure"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Sets a colored overlay on your group image on hover.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
