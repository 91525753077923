/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import { combineReducers } from "redux";

import main from "./main";
import events from "./events";
import featuredEvents from "./featuredEvents";
import user from "./user";
import calendars from "./calendars";
import calendar from "./calendar";
import integrations from "./integrations";
import integration from "./integration";
import domains from "./domains";
import users from "./users";
import fonts from "./fonts";
import autologin from "./autologin";
import subscriptionPlans from "./subscriptionPlans";
import imports from "./imports";
import coupons from "./coupons";
import licenses from "./licenses";
import usersLicenses from "./usersLicenses";
import subscription from "./subscription";
import payout from "./payout";
import payouts from "./payouts";
import filters from "./filters";
import groups from "./groups";
import group from "./group";
import alerts from "./alerts";
import alert from "./alert";
import groupItems from "./groupItems";
import queueWorkers from "./queueWorkers";
import queueJobs from "./queueJobs";
import integrationMetadata from "./integrationMetadata";
import { connectRouter } from "connected-react-router";
import alertEvents from "./alertEvents";
import widget from "./widget";
import widgets from "./widgets";
import widgetItems from "./widgetItems";
import organizations from "./organizations";
import organization from "./organization";
import currentOrganization from "./currentOrganization";
import organizationMembers from "./organizationMembers";
import dismissedAlerts from "./dismissedAlerts";
import appTags from "./appTags";
import affiliates from "./affiliates";
import sortableTables from "./sortableTables";

import widgetItemsCache from "./../../calendar/reducers/widgetItemsCache";
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    main,
    //router,
    user,
    calendars,
    calendar,
    integrations,
    integration,
    domains,
    events,
    featuredEvents,
    users,
    fonts,
    autologin,
    subscriptionPlans,
    subscription,
    imports,
    coupons,
    licenses,
    usersLicenses,
    payout,
    payouts,
    filters,
    groups,
    group,
    alerts,
    alert,
    integrationMetadata,
    groupItems,
    queueWorkers,
    queueJobs,
    alertEvents,
    widget,
    widgets,
    widgetItems,
    organizations,
    organization,
    currentOrganization,
    organizationMembers,
    dismissedAlerts,
    appTags,
    widgetItemsCache,
    affiliates,
    sortableTables,
  });
