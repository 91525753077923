/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

import React, { useCallback, useContext, useState } from "react";

import { Link } from "react-router-dom";
import SidebarTab from "./../../../component/WidgetSettings/SidebarTab";
import LoadingIndicator from "../../../../calendar/components/LoadingIndicator";
import SuccessAlert from "../../../component/SuccessAlert";
import PropTypes from "prop-types";
import ApiError from "../../../component/ApiError";
import ExpandableAlert from "../../../component/ExpandableAlert";
import { calendarFeaturesCheck, groupFeaturesCheck } from "../../../tools";
import { useCalendarsStore } from "../../../../../hooks/redux/calendars";
import { PLAN_NAMES } from "../../../../../consts";
import WidgetContext from "../../../contexts/WidgetContext";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import Accordion from "../../../component/WidgetSettings/Acordion";
import RadioInput from "../../../component/WidgetSettings/RadioInput";
import ShareableUrl from "../../../component/WidgetSettings/ShareableUrl";
import classNames from "classnames";
import WidgetInstallModal from "../../../modals/WidgetInstallModal";
import QRCode from "../../../component/WidgetSettings/QRCode";

const InstallTab = (props) => {
  const { widgetModel, widget } = props;
  const widgetType = widget.model.widget_type;
  const { calendars } = useCalendarsStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const [showInstallModal, setShowInstallModal] = useState(false);
  const widgetContext = useContext(WidgetContext);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onShowInstallModal = useCallback(() => {
    setShowInstallModal(true);
  }, [setShowInstallModal]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onCloseInstallModal = useCallback(() => {
    setShowInstallModal(false);
  }, [setShowInstallModal]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onPublishClick = useCallback(
    (e) => {
      props.onUpdateStatus(1);
    },
    [props.onUpdateStatus, widget]
  );

  const featureCheck =
    widgetModel && widgetModel.integration && !widget.update
      ? widgetType === "calendar"
        ? calendarFeaturesCheck
        : groupFeaturesCheck
      : null;

  const publishIssues = featureCheck
    ? featureCheck(
        { ...widgetModel, available: 1 },
        currentOrganization,
        calendars
      )
    : [];

  const publishRestrictionWarning =
    !widgetModel.available && publishIssues.length ? (
      <div className={"alert alert-danger mt-3 p-2"}>
        <i className={"material-icons text-danger icon-sm"}>warning</i> You
        cannot publish this widget because:
        <ul className={"pl-3"}>
          {publishIssues.map((i, idx) => (
            <li key={`cv-${idx}`}>{i}</li>
          ))}
        </ul>
        {props.recommendedPlan && (
          <span>
            Click here to upgrade to the minimum required plan{" "}
            <strong>{PLAN_NAMES[props.recommendedPlan]}</strong>.
          </span>
        )}
        <div className={"d-flex justify-content-center"}>
          <Link to={"/billing"} className={"btn btn-default btn-sm"}>
            Upgrade now
          </Link>
        </div>
      </div>
    ) : null;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChangeStatus = useCallback(
    (field) => (e) => {
      props.onUpdateStatus(!(widget.model.available * 1) ? 1 : 0);
    },
    [props.onUpdateStatus, widget]
  );

  const installModal = showInstallModal ? (
    <WidgetInstallModal
      widgetType={widgetType}
      uuid={widget.model.uuid}
      slug={widget.model.slug}
      show={showInstallModal}
      onClose={onCloseInstallModal}
      widgetTypePrintable={widgetContext.widgetDisplayName}
      widgetTemplate={widget.model.template}
    />
  ) : null;

  const installButton =
    widgetModel.available && !publishIssues.length ? (
      <div className={"my-2"}>
        <button
          className={classNames("btn btn-primary w-100", {
            disabled: props.disabled || !widgetModel.available,
          })}
          disabled={props.disabled || !widgetModel.available}
          onClick={onShowInstallModal}
        >
          Install
        </button>
      </div>
    ) : null;

  const widgetTypeName = props.widgetTypeName.toLowerCase();

  const publishCard = (
    <Accordion
      title={"Publish"}
      subtitle={`Your ${widgetTypeName} is ${
        widget.model.available * 1 == 0 ? " not" : ""
      } published.`}
      keepExpanded={true}
    >
      <RadioInput
        fieldName={"available"}
        label={"Make this widget available to others"}
        onChange={onChangeStatus}
        checked={widgetModel.available}
        disabled={!widgetModel.available}
        tooltip={
          widgetModel.available * 1 === 0
            ? {
                message: `In order to share your ${widgetTypeName} and make it accessible by
              others, change it status to published. You can make it here using
              the toggle button.`,
              }
            : null
        }
      />

      {widget.model.available * 1 == 0 ? (
        <ExpandableAlert
          icon={null}
          errorLevel={"info"}
          header="Recommended helpful tip:"
          body={
            <span>
              In order to share your {widgetTypeName} and make it accessible by
              others, change it status to published. You can make it here using
              the toggle below.
            </span>
          }
        />
      ) : null}
      {publishRestrictionWarning}

      {installButton}
      <SuccessAlert className={"p-2"}>
        {widget.updateSuccess ? "Changes have been saved." : null}
      </SuccessAlert>
    </Accordion>
  );

  const footer = !props.disabled ? (
    <div>
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
    </div>
  ) : (
    <LoadingIndicator />
  );

  const unpublishedWarning = !widgetModel.available ? (
    <div
      className={
        "alert alert-warning fade show d-flex align-items-center flex-column p-2"
      }
    >
      This widget is unpublished. Publish your widget first.
      <button className="btn btn-primary btn-sm m-2" onClick={onPublishClick}>
        Publish
      </button>
    </div>
  ) : null;

  return (
    <SidebarTab id="install_tab" show={false} footer={footer}>
      {publishCard}

      {widget.model.available && props.shareableUrl ? (
        <>
          <Accordion
            title={"Hosted Link and Redirection"}
            subtitle={
              "Copy the widget-hosted Link and/or set the destination for navigation."
            }
          >
            <ShareableUrl
              url={props.shareableUrl}
              widgetModel={widgetModel}
              widgetTypeName={widgetContext.widgetDisplayName}
              onUpdateSettings={props.onUpdateWidgetSettings}
              onSubmit={props.onSubmit}
            />
          </Accordion>
          <Accordion
            title={"QR code"}
            subtitle={
              "Download QR code and use it in your posters/social media."
            }
          >
            <QRCode url={props.shareableUrl} title={widgetModel.name} />
          </Accordion>
        </>
      ) : (
        <div />
      )}
      <div className={"d-flex flex-column"}>
        <div className={"mx-2"}>{unpublishedWarning}</div>
        {installModal}
      </div>
    </SidebarTab>
  );
};

InstallTab.defaultProps = {
  widgetTypeName: "Widget", // or Calendar
};

InstallTab.propTypes = {
  // form fields and errors
  widget: PropTypes.object.isRequired,
  widgetModel: PropTypes.object,

  errors: PropTypes.object,

  onSubmit: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,

  isNew: PropTypes.bool,
  hasDomains: PropTypes.bool,

  disabled: PropTypes.bool,

  widgetTypeName: PropTypes.string,
  widgetType: PropTypes.string,
  shareableUrl: PropTypes.string,

  recommendedPlan: PropTypes.string,

  onUpdateWidgetSettings: PropTypes.func,
};

export default InstallTab;
