import { useHistory, useLocation } from "react-router-dom";
import { useMemo } from "react";

const useRedirectUrl = () => {
  const { search } = useLocation();
  const history = useHistory();

  const redirectUrl = useMemo(() => {
    const query = new URLSearchParams(search);

    const redirectParam = query.get("redirect");

    if (!redirectParam) {
      return null;
    }
    return decodeURIComponent(redirectParam);
  }, [search]);

  /**
   * Create a URL with a redirect parameter
   * @param url
   * @returns {*|string}
   */
  const createUrlWithRedirect = (url) => {
    if (!redirectUrl) {
      return url;
    }

    return `${url}${url.includes("?") ? "&" : "?"}redirect=${encodeURIComponent(
      redirectUrl
    )}`;
  };

  /**
   * Redirect to the original URL user was trying to access or to the provided URL
   * @param {string} url
   */
  const redirectOrNavigateTo = (url) => {
    // redirect to the page user was trying to access before login, use redirect query param if available

    if (!redirectUrl || redirectUrl === "") {
      history.push(url);
    } else {
      if (redirectUrl.startsWith("/")) {
        history.push(redirectUrl);
      } else {
        window.location = redirectUrl;
      }
    }
  };

  return { redirectUrl, createUrlWithRedirect, redirectOrNavigateTo };
};

export default useRedirectUrl;
