import PropTypes from "prop-types";

export const StyleShape = PropTypes.shape({
  selector: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
    PropTypes.func,
  ]),
  property: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
});

export const DependencyShape = PropTypes.shape({
  field: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  message: PropTypes.string,
});

export const ShowShape = PropTypes.shape({
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  or: PropTypes.bool,
});

export const BuilderOptionPropTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  name: PropTypes.string.isRequired,
  templates: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.oneOfType([PropTypes.arrayOf(StyleShape), StyleShape]),
  tooltip: PropTypes.shape({
    url: PropTypes.string,
    message: PropTypes.string,
  }),
  dependency: PropTypes.oneOfType([
    PropTypes.arrayOf(DependencyShape),
    DependencyShape,
  ]),
  show: PropTypes.oneOfType([
    PropTypes.arrayOf(ShowShape),
    ShowShape,
    PropTypes.bool,
  ]),
  control: PropTypes.shape({
    control: PropTypes.oneOf([
      "color",
      "text",
      "number",
      "dropdown",
      "checkbox",
      "radio",
      "fontFamily",
      "fontSize",
    ]),
  }).isRequired,
};

export const BuilderInputSetupPropTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  updateDesignProp: PropTypes.func.isRequired,
  style: PropTypes.oneOfType([PropTypes.arrayOf(StyleShape), StyleShape]),

  tooltip: PropTypes.shape({
    url: PropTypes.string,
    message: PropTypes.string,
  }),
  show: PropTypes.oneOfType([
    PropTypes.arrayOf(ShowShape),
    ShowShape,
    PropTypes.bool,
  ]),
  dependency: PropTypes.oneOfType([
    PropTypes.arrayOf(DependencyShape),
    DependencyShape,
  ]),

  showDependency: PropTypes.bool,
  settingsName: PropTypes.string.isRequired,
};
