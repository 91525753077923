/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */
import { WT } from "../consts/widget";

const initialState = {
  columns: {
    [WT.calendar]: {
      field: null,
      order: null,
    },
    [WT.groups]: {
      field: null,
      order: null,
    },
    [WT.alerts]: {
      field: null,
      order: null,
    },
    [WT.signups]: {
      field: null,
      order: null,
    },
  },
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "WIDGETS_SORTING_SET":
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.widgetType]: {
            field: action.field,
            order: action.order,
          },
        },
      };

    case "LOGOUT":
      return initialState;

    default:
      return state;
  }

  return state;
}
