/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/06/2022
 */

import React from "react";
import DetailsRow from "./DetailsRow";

import RecurrenceSeriesTable from "./RecurrenceSeriesTable";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {Array} series
 * @param {string} eventId
 * @param {string} eventName
 * @param {string} description
 * @param {func} onShowMore
 * @param {boolean} showSeries
 * @param {boolean} isAllDayEvent
 * @param {number} numberOfNextEvents
 * @returns {Element}
 * @constructor
 */
const RecurrenceSeries = ({
  series,
  eventId,
  eventName,
  description,
  onShowMore,
  showSeries,
  numberOfNextEvents,
  isAllDayEvent,
}) => {
  const { t } = useTranslation();

  const recurrencesTable = showSeries ? (
    <RecurrenceSeriesTable
      numberOfNextEvents={numberOfNextEvents}
      series={series}
      eventName={eventName}
      eventId={eventId}
      onShowMore={onShowMore}
      isAllDayEvent={isAllDayEvent}
    />
  ) : null;

  return (
    <DetailsRow
      title={t("Future event times in this recurring event series")}
      icon="replay"
    >
      <p
        id={`series-${eventId}`}
        className={"dce-future-event-times-in-series"}
      >
        {t("This event repeats")} {description}.
      </p>

      {recurrencesTable}
    </DetailsRow>
  );
};

export default RecurrenceSeries;
