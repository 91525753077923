/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/05/2024
 */

import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 *
 * @param {Number} value
 * @param {Number} min
 * @param {Number} max
 * @returns {boolean}
 */
const isInRange = (value, min, max) => {
  if (min && value < min) {
    return false;
  } else if (max && value > max) {
    return false;
  }
  return true;
};

const NumberInput = (props) => {
  const [minMaxWarning, setMinMaxWarning] = React.useState(false);
  const inputRef = useRef();

  const onChange = useCallback(
    (event) => {
      props.updateDesignProp([
        {
          field: props.name,
          value: event.target.value,
        },
      ]);
    },
    [props.updateDesignProp]
  );

  useEffect(() => {
    if (props.min && props.max) {
      if (!props.disabled && inputRef.current) {
        if (
          !isInRange(
            Number.parseInt(inputRef.current.value),
            props.min,
            props.max
          )
        ) {
          setMinMaxWarning(true);
        } else {
          setMinMaxWarning(false);
        }
      } else {
        setMinMaxWarning(false);
      }
    }
  }, [props.disabled, minMaxWarning, inputRef.current]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onKeyUp = useCallback(
    (e) => {
      if (!props.min && !props.max) {
        return;
      }
      const value = Number.parseInt(e.target.value);
      if (!isInRange(value, props.min, props.max)) {
        setMinMaxWarning(true);
      } else {
        setMinMaxWarning(false);
      }
    },
    [minMaxWarning]
  );

  const outOfRangeWarning =
    minMaxWarning && !props.disabled ? (
      <div className="invalid-feedback">
        Value out of range. The value must be between {props.min} and{" "}
        {props.max}.
      </div>
    ) : null;

  return (
    <React.Fragment>
      <input
        type="number"
        className={classNames("form-control", {
          "is-invalid": outOfRangeWarning ? true : false,
        })}
        ref={inputRef}
        disabled={props.disabled}
        value={!props.disabled ? props.customInputValue : ""}
        onChange={onChange}
        min={props.min ?? undefined}
        max={props.max ?? undefined}
        onKeyUp={onKeyUp}
        onBlur={onKeyUp}
      />
      {props.postLabel || null}
      {outOfRangeWarning}
    </React.Fragment>
  );
};

NumberInput.defaultProps = {};

NumberInput.propTypes = {
  disabled: PropTypes.bool,
};

export default NumberInput;
