/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/08/2018.
 */

import React, { useEffect } from "react";
import PageHeader from "./PageHeader";
import BreadcrumbBar from "../../component/BreadcrumbBar";
import classNames from "classnames";
import SidebarMenu from "./SidebarMenu";
import { updateJquery } from "../../tools";

/**
 * @param {string} title
 * @param {string=""} className
 * @param {string} icon
 * @param {object} iconImg
 * @param {string} sidebarId
 * @param {array} headerControls
 * @param {array} children
 *
 * @returns {Element}
 * @constructor
 */
const Index = ({
  title,
  className = "",
  icon,
  iconImg,
  sidebarId,
  headerControls,
  children,
}) => {
  useEffect(() => {
    updateJquery();

    return () => {
      updateJquery();
    };
  }, []);

  const content = title ? (
    <div
      className={classNames(
        "page-wrapper page-wrapper-with-breadcrumbs",
        className
      )}
    >
      <div className="content container-fluid">
        <BreadcrumbBar
          title={title}
          icon={icon}
          iconImg={iconImg}
          sidebarId={sidebarId}
          controls={headerControls}
        />

        {children}
      </div>
    </div>
  ) : (
    children
  );

  return (
    <div className="main-wrapper">
      <PageHeader />
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <SidebarMenu sidebarId={sidebarId} />
        </div>
      </div>

      {content}
    </div>
  );
};

export default Index;
