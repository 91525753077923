/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2025.
 */

import React, { useState, useMemo, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import Tos from "../../templates/PageTemplate/Tos";
import AxiosConfig from "./../../../../AxiosConfig";
import ApiError from "./../../component/ApiError";
import appConfig from "../../../../appConfig";
import useQuery from "../../../../hooks/useQuery";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import axios from "axios";
import { ucFirst } from "../../../../helpers/string";
import usePluginParams from "./hooks/usePluginParams";

const PLuginNamesMapping = {
  wp: "WordPress Website",
};

const InfoLabel = ({ label, value }) => {
  if (!value) {
    return null;
  }

  return (
    <div>
      <span className={"text-secondary"}>{ucFirst(label)}</span> {value}
    </div>
  );
};
const PluginsDiscardTokenPage = (props) => {
  const params = useQuery();
  const requestData = usePluginParams(params);
  const removeWebsiteInputRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);
  const isLoading = submitting;

  const { currentOrganization } = useCurrentOrganizationStore();

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setSubmitting(true);
      axios
        .delete(
          AxiosConfig.getEndpointAddress() +
            `/api4/plugins/discard_token.json?app_name=${requestData.name}&app_type=${requestData.type}&domain=${requestData.domain}&remove_website=${removeWebsiteInputRef.current.checked}`,
          AxiosConfig.getAuthConfig()
        )
        .then((response) => {
          window.location = requestData.callbackUrl + `&revoke=true`;
        })
        .catch((error) => {
          setSubmitting(false);
          console.log(error);
          // todo: handle error
        });

      // console.log("submit");

      return false;
    },
    [submitting]
  );

  /**
   *
   * @type {function(*): Promise<boolean>}
   */
  const onCancel = useCallback(
    async (e) => {
      e.preventDefault();

      window.location = requestData.callbackUrl + `&error=cancelled`;

      return false;
    },
    [submitting]
  );

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className={"d-flex justify-content-center"}>
            <div className="card-box card-box_auth w-40vw">
              <div className="card-title">
                <h3 className="account-header d-flex flex-column align-items-center ">
                  Disconnect {PLuginNamesMapping[requestData.type]}
                  <div className={"rev-plugin-auth--icons"}>
                    <img
                      src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${requestData.url}&size=64`}
                    />
                    <span className="material-icons">link_off</span>
                    <img
                      src={`/img/branding/${appConfig.branding}/icon/ms-icon-310x310.png`}
                    />
                  </div>
                </h3>
              </div>
              <div className={"card-body"}>
                <p>
                  This action will remove the connection between your WordPress
                  site and {appConfig.displayName}. As a result:
                </p>

                <ul className={"pl-3"}>
                  <li>
                    Your existing widgets will continue to work unless the{" "}
                    {appConfig.displayName} plugin is deactivated in your
                    WordPress plugin settings.
                  </li>
                  <li>
                    You will no longer see the list of widgets in WordPress,
                    making it harder to embed new widgets or manage existing
                    ones.
                  </li>
                  <li>
                    If you select the option{" "}
                    <strong>"Remove my website"</strong> below, all widgets will
                    stop working immediately.
                  </li>
                </ul>
                <form action="/" onSubmit={onSubmit} onReset={onCancel}>
                  <div>
                    <h4>Website info</h4>
                    <div className={"m-b-30"}>
                      <InfoLabel label={"site name"} value={requestData.name} />
                      <InfoLabel
                        label={"admin email"}
                        value={requestData.adminEmail}
                      />
                      <InfoLabel
                        label={"version"}
                        value={requestData.version}
                      />
                      <InfoLabel label={"url"} value={requestData.url} />
                      {/*<InfoLabel*/}
                      {/*  label={"callback"}*/}
                      {/*  value={requestData.callbackUrl}*/}
                      {/*/>*/}
                    </div>
                    <div className={"m-b-30"}>
                      <h4>Account info</h4>
                      <div className={"d-flex gap-xs"}>
                        <InfoLabel
                          label={"organization name"}
                          value={currentOrganization.model.name}
                        />
                        {/*Change*/}
                      </div>
                    </div>
                  </div>

                  <div className={"checkbox"}>
                    <label htmlFor={"remove_website"}>
                      <input
                        type={"checkbox"}
                        id={"remove_website"}
                        ref={removeWebsiteInputRef}
                      />{" "}
                      Remove my website ({requestData.domain}) from{" "}
                      {appConfig.displayName} websites
                    </label>
                  </div>

                  <div
                    className={
                      "d-flex align-items-center justify-content-end gap-xs"
                    }
                  >
                    <button
                      type={"reset"}
                      className={"btn btn-outline-primary"}
                      disabled={isLoading}
                    >
                      Cancel
                    </button>
                    <button
                      type={"submit"}
                      className={"btn btn-danger"}
                      disabled={isLoading}
                    >
                      Confirm disconnect
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className={"d-flex justify-content-center m-b-30"}>
            <div className={"card-box_auth w-40vw d-flex gap-xs"}>
              <a href={appConfig.helpdeskAddress}>
                Helpdesk <i className={"material-icons icon-xs"}>open_in_new</i>
              </a>
              {appConfig.branding === "dc" ? (
                <a href={`https://feedback.${appConfig.domain}`}>
                  Feedback{" "}
                  <i className={"material-icons icon-xs"}>open_in_new</i>
                </a>
              ) : null}
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

export default PluginsDiscardTokenPage;
