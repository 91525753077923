/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2025.
 */
import { useMemo } from "react";

export const usePluginParams = (queryParams) => {
  const requestData = useMemo(() => {
    const url = new URL(queryParams.get("url"));

    return {
      callbackUrl: queryParams.get("callback").replace(/&amp;/g, "&"),
      name: queryParams.get("sitename"),
      adminEmail: queryParams.get("adminemail"),
      type: queryParams.get("type"),
      version: queryParams.get("version"),
      url: queryParams.get("url"),
      domain: url.host,
    };
  }, []);

  return requestData;
};

export default usePluginParams;
