/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/04/2022.
 */

import { createContext } from "react";
import { IT } from "../../../consts";
import CCBStrategy from "./CCBStrategy";
import PCOStrategy from "./PCOStrategy";

const IntegrationContext = createContext(null);

/**
 *
 * @param integrationType
 * @returns {GroupsIntegrationStrategy}
 */
export const getStrategy = (integrationType) => {
  switch (integrationType) {
    case IT.ccb:
      return new CCBStrategy();
    case IT.pco:
      return new PCOStrategy();
  }
};

export default IntegrationContext;
