/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2023.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

export function fetchOrganization(uuid) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_FETCH" });

    const url = "/api/organizations/" + uuid + ".json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

export function editOrganization(uuid, data, isAdminRequest = false) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_EDIT", isAdminRequest });

    const url = "/api/organizations/" + uuid + ".json";

    return axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_EDIT_FULFILLED",
          payload: response.data,
          isAdminRequest,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_EDIT_REJECTED",
          payload: error,
          isAdminRequest,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @param data
 * @param isAdminRequest
 * @returns {function(*): Promise<void>}
 */
export function createOrganization(data = false) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_CREATE" });

    const url = "/api/organizations.json";

    return axios
      .post(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_CREATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_CREATE_REJECTED",
          payload: error,
        });
      });
  };
}

export function fetchBillingHistory() {
  return function (dispatch) {
    dispatch({ type: "BILLING_HISTORY_FETCH" });

    const url = "/api/organizations/billing_history.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BILLING_HISTORY_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BILLING_HISTORY_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function editOrganizationSubscription(uuid, expiry) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_EDIT" });

    const url = "/api/subscriptions/edit_organization/" + uuid + ".json";

    return axios
      .put(
        url,
        {
          expiry,
        },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_EDIT_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_EDIT_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param type
 * @param file
 * @returns {function(*): Promise<void>}
 */
export const uploadBrandingFile = (type, file) => {
  return (dispatch) => {
    dispatch({ type: "UPLOAD_BRANDING_FILE" });

    const url = "/api/organizations/upload_branding_file.json";

    const formData = new FormData();
    formData.append("type", type);

    if (file instanceof File) {
      formData.append("file", file);
    }
    const config = AxiosConfig.getAuthConfig();
    config.headers["Content-Type"] = "multipart/form-data";

    return axios
      .post(url, formData, config)
      .then((response) => {
        dispatch({
          type: "UPLOAD_BRANDING_FILE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "UPLOAD_BRANDING_FILE_REJECTED",
          payload: error,
        });
      });
  };
};
