/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/02/2025
 */

import React, { useCallback, useEffect, useState } from "react";
import SubscribePopup from "../../../WidgetsComponents/SubscribePopup";
import { useHistory } from "react-router-dom";

/**
 *
 * @param eventName
 * @param integrationUUID
 * @param widgetUUID
 * @param ICSID
 * @returns {{subscribePopup: React.JSX.Element, onShow: (function(*): boolean)}}
 */
const useSubscribePopup = ({
  eventName,
  integrationUUID,
  widgetUUID,
  ICSID,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (window.location.hash === "#add-to-calendar") {
      setShowPopup(true);
    } else if (showPopup) {
      setShowPopup(false);
    }
  }, [window.location.hash]);

  /**
   *
   * @type {function(*): boolean}
   */
  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      // remove #add-to-calendar from the url but keep the rest of the url
      history.push(window.location.pathname + window.location.search);
      return false;
    },
    [showPopup]
  );

  const subscribePopup = showPopup ? (
    <SubscribePopup
      title={eventName}
      onClose={onClose}
      RSSUrl={null}
      iCalPath={`${integrationUUID}/${widgetUUID}/${ICSID}.ics`}
    />
  ) : null;
  /**
   *
   * @type {function(*): boolean}
   */
  const onShow = useCallback(
    (e) => {
      e.preventDefault();
      setShowPopup(true);
      return false;
    },
    [showPopup]
  );

  return { subscribePopup, onShow };
};

export default useSubscribePopup;
