import React from "react";
import PropTypes from "prop-types";
import TaskItem from "./TaskItem";
import appConfig from "../../../../appConfig";

const WelcomeScreen = (props) => {
  const { isActivated, hasSync, hasCalendar, hasWebsite } = props;
  if (isActivated && hasSync && hasCalendar && hasWebsite) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="card-box">
          <h4 className="card-title">Configuration Checklist</h4>

          <p>
            Before you share your first calendar, there are a few things to do.
            Here is a checklist that helps you set everything up.
          </p>

          <div className="task-wrapper">
            <div className="task-list-container">
              <div className="task-list-body">
                <ul id="task-list">
                  <TaskItem completed={isActivated}>Activate account</TaskItem>
                  <TaskItem completed={hasSync} linkUrl={"/integrations"}>
                    Integrate with{" "}
                    {appConfig.branding !== "rev"
                      ? "the platform of your choice"
                      : "Google Calendar™"}
                  </TaskItem>
                  <TaskItem completed={hasCalendar} linkUrl={"/calendar/new"}>
                    Create your first calendar widget
                  </TaskItem>
                  <TaskItem completed={hasWebsite} linkUrl={"/domains"}>
                    Grant calendar access to your websites
                  </TaskItem>
                  {}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WelcomeScreen.propTypes = {
  isActivated: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  hasSync: PropTypes.bool,
  hasCalendar: PropTypes.bool,
  hasWebsite: PropTypes.bool,
};

export default WelcomeScreen;
