/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/11/2024
 */

import React from "react";

const Card = ({}) => (
  <div className={"card-view--card dc-flex-1 rev--loader-card-item "}>
    <div className={"rev-preloader-block"}></div>
  </div>
);

const LoaderIndicator = () => {
  return (
    <>
      <Card />
      <Card />
      <Card />
    </>
  );
};

export default LoaderIndicator;
