/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/01/2025.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as appActions from "../../actions/main";

export function useSortableTablesStore() {
  const dispatch = useDispatch();
  const _sortableTables = useSelector((store) => store.sortableTables);

  const setWidgetsSorting = useCallback(
    (wt, field, order) =>
      dispatch(appActions.setWidgetsSorting(wt, field, order)),
    []
  );

  return {
    sortableTables: _sortableTables,
    setWidgetsSorting,
  };
}
