/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/11/2024
 */

import React from "react";

const Card = () => (
  <div className={"card-slider_card-wrapper rev--loader-slider-item "}>
    <div className={"card-view--card rev-preloader-block"}></div>
  </div>
);

const LoaderIndicatorSlider = () => {
  return <Card />;
};

export default LoaderIndicatorSlider;
