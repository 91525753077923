import React from "react";
import PropTypes from "prop-types";
import useSharePopup from "../../../../hooks/useSharePopup";
import { ShareIcon } from "../../../../consts";
import { useTranslation } from "react-i18next";

const ShareLinkButton = (props) => {
  const { onShare, sharePopup } = useSharePopup(props);
  const { t } = useTranslation();

  return (
    <>
      <a onClick={onShare} className={"share-event share-event--link"}>
        <i className="material-icons">{ShareIcon}</i> {t("Share")}
      </a>
      {sharePopup}
    </>
  );
};

ShareLinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  onShareSuccess: PropTypes.func,
  onShareError: PropTypes.func,
};

export default ShareLinkButton;
