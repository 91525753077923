/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2018.
 */

import React from "react";

import EmailSignUpForm from "./RegisterPage/EmailSignUpForm";
import { Link } from "react-router-dom";
import Tos from "./../templates/PageTemplate/Tos";
import UnauthorizedUserMobileHelp from "./../component/UnauthorizedUserMobileHelp";
import appConfig from "../../../appConfig";
import useRedirectUrl from "../hooks/useRedirectUrl";

const RegisterPage = (props) => {
  const { createUrlWithRedirect } = useRedirectUrl();

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>

          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">Create an account</h3>

              <EmailSignUpForm />

              <div className="text-center">
                <Link to={createUrlWithRedirect("/login")}>
                  Already have an account?
                </Link>
              </div>
              <UnauthorizedUserMobileHelp />
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
