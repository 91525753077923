/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./GroupsPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";
import { getGroupCurrentData } from "../../../actions/group";
import { useGroupsStore } from "../../../hooks/redux/groups";
import { useGroupStore } from "../../../hooks/redux/group";
import { formatError } from "../../../helpers/errors";
import NotificationPopup from "../component/NotificationPopup";
import useNotificationToast from "../hooks/useNotificationToastHook";
import { groupFeaturesCheck, updateJquery } from "../tools";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import appConfig from "../../../appConfig";
import { useSortableTablesStore } from "../../../hooks/redux/sortableTables";
import { useSortableTable } from "../component/SortableTable/useSortableTable";
import { WT } from "../consts/widget";
import SortableTable from "../component/SortableTable";

const columns = [
  {
    id: "disabledStatus",
    label: "",
    minWidth: "20px",
  },
  {
    id: "name",
    label: "Name",
    minWidth: "30%",
    sort: (direction) => (a, b) =>
      direction === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name),
  },
  {
    id: "integration",
    label: "Integration",
    minWidth: "15%",
    sort: (direction) => (a, b) => {
      const integrationA = a.integration ? a.integration.name : "";
      const integrationB = b.integration ? b.integration.name : "";

      return direction === "asc"
        ? integrationA.localeCompare(integrationB)
        : integrationB.localeCompare(integrationA);
    },
  },
  {
    id: "template",
    label: "Template",
    minWidth: "20%",
  },
  {
    id: "status",
    label: "Status",
    minWidth: "10%",
  },
  {
    id: "lastEdited",
    label: "Last edited",
    minWidth: "10%",
    sort: (direction) => (a, b) =>
      direction === "asc" ? a.modified - b.modified : b.modified - a.modified,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    className: "text-right",
  },
];

const GroupsPage = (props) => {
  const history = useHistory();

  const { groups, fetchGroups } = useGroupsStore();
  const { group, updateGroup, setGroup, deleteGroup } = useGroupStore();
  const { addNotification } = useNotificationToast();
  const { currentOrganization } = useCurrentOrganizationStore();

  const { sortableTables } = useSortableTablesStore();

  const { sortedData } = useSortableTable(
    groups.collection,
    columns,
    sortableTables.columns[WT.groups].field,
    sortableTables.columns[WT.groups].order
  );

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (group.updateError) {
      addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">The following error occurred:</p>
            {formatError(group.updateError).join("")}
          </NotificationPopup>
        ),
      });
    }
  }, [group.updateError]);

  /**
   *
   * @param domain
   */
  const onChangeStatus = async (group) => {
    const groupData = getGroupCurrentData({ ...group });
    groupData.available = group.available ? 0 : 1;

    const issues = groupFeaturesCheck(groupData, currentOrganization);
    if (issues.length) {
      addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">
              <i className={"material-icons"}>warning</i> You cannot publish
              this group widget because:
            </p>
            <ul className={"notification-message pl-4"}>
              {issues.map((i, idx) => (
                <li key={`per-${idx}`}>{i}</li>
              ))}
            </ul>
          </NotificationPopup>
        ),
      });

      return false;
    }
    await updateGroup(group.uuid, groupData);
    setGroup(null);
  };

  useEffect(() => {
    /**
     * If no groups available, navigate to new group page.
     * Handle back button so user does not enter an infinite loop.
     */
    if (groups.fetchSuccess && !groups.collection.length) {
      if (history.action === "POP" && props) {
        history.go(-1);
      } else {
        history.push("/group/new");
      }
    }
    updateJquery();
  }, [groups.fetchSuccess]);

  /**
   *
   * @param group
   * TODO
   */
  const onClone = async (group) => {
    const groupData = getGroupCurrentData({ ...group });
    groupData.uuid = null;
    groupData.available = 0;
    groupData.name = "Copy of " + groupData.name;

    await updateGroup(null, groupData);
    await setGroup(null);
  };

  /**
   *
   */
  const onDelete = (group) => {
    if (
      window.confirm(
        "Are you sure you want to delete group widget " +
          group.name +
          "? Changes are irreversible!"
      )
    ) {
      deleteGroup(group.uuid);
    }
  };

  const loading = groups.fetch || groups.delete || groups.sync;

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const groupRows = sortedData.map((item, idx) => {
    return (
      <TableRow
        disabled={loading}
        key={idx}
        group={item}
        onChangeStatus={onChangeStatus}
        onDelete={onDelete}
        groupEditUUID={groups.updateGroupUUID}
        onClone={onClone}
      />
    );
  });

  const groupsData = groups.collection.length ? (
    <SortableTable columns={columns} rows={groupRows} widgetType={WT.groups} />
  ) : (
    loadingIndicator
  );

  return (
    <PageTemplate
      sidebarId="groups"
      title={"Groups"}
      icon={"group"}
      headerControls={[
        <Link
          key="/group/new"
          to="/group/new"
          className="btn btn-primary btn-rounded pull-right d-flex"
        >
          <i className="material-icons">add</i> Create new
        </Link>,
      ]}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive min-height-50">
            {groupsData}

            <ApiError
              error={groups.deleteError}
              defaultErrorMessage={
                "Could not delete the domain. Please try again"
              }
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default GroupsPage;
