/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React, { useContext, useMemo, useState } from "react";
import SelectorGroup from "./../Selectors/SelectorGroup";
import MonthSelector from "./../CalendarHeader/MonthSelector";
import CalendarHeaderLabel from "./../CalendarHeader/CalendarHeaderLabel";
import IntegrationContext from "../../strategies/IntegrationContext";
import { useFiltersStore } from "../../../../hooks/redux/filters";
import LoadingIndicator from "./../LoadingIndicator";
import CalendarHeaderSelectors from "./../CalendarHeaderSelectors";
import useHeaderFilters from "../../../../hooks/useHeaderFilters";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import ShareCalendarButton from "../ShareCalendarButton";
import { calendarPublicName } from "../../helpers";
import SubscribeCalendarButton from "../SubscribeCalendarButton";
import SearchBar from "../SearchBar";

/**
 *
 * @param {function} onChangeFilter
 * @param {function} onChangeDate
 * @param {function} selectedFilters
 * @param {function} onUpcomingNavigationChange
 * @param {boolean} showMonthSelector
 * @param {boolean} singleEvent
 * @param {function} onSearch
 * @param {object} calendar
 * @param {object} currentDate
 * @param {Array} events
 * @returns {Element}
 * @constructor
 */
const CalendarHeader = ({
  onChangeFilter,
  onChangeDate,
  selectedFilters,
  onUpcomingNavigationChange,
  showMonthSelector = true,
  singleEvent = false,
  onSearch,
  calendar,
  currentDate,
  events,
}) => {
  const [state, setState] = useState({
    showFilters: true,
  });

  // const { currentDate, events, calendar, singleEvent } = props;
  const { fetchCalendarFilters, filters } = useFiltersStore();
  const integrationStrategy = useContext(IntegrationContext);
  const widgetSettings = useContext(WidgetSettingsContext);

  useHeaderFilters(
    integrationStrategy,
    widgetSettings,
    fetchCalendarFilters,
    calendar.model.uuid,
    state.showFilters,
    []
  );

  const showMonthHeader =
    Number.parseInt(widgetSettings["showMonthHeader"]) === 1;

  const kindOfNumberOfEvents = widgetSettings["eventsNumberOption"] || "all";

  const showPaginationNavigation =
    Number.parseInt(widgetSettings["showPaginationNavigation"]) === 1;

  const showNavigation =
    !singleEvent &&
    ["upcoming", "all"].indexOf(kindOfNumberOfEvents) >= 0 &&
    showPaginationNavigation;

  const showUpcomingNavigationValue =
    Number.parseInt(widgetSettings["showUpcomingNavigation"]) === 1;

  const showUpcomingNavigation =
    showUpcomingNavigationValue && kindOfNumberOfEvents == "upcoming-limit";

  const showFilterLabels = widgetSettings["filterNamesHeader"];

  const filtersHeader = useMemo(() => {
    return showFilterLabels &&
      calendar.model.filter_settings &&
      calendar.model.filter_settings.filters
      ? calendar.model.filter_settings.filters.groups
          .map((group) => group.rules.map((rule) => rule.label))
          .join(", ")
      : null;
  }, [calendar.model.filter_settings]);

  const topHeader =
    showFilterLabels && filtersHeader != "" ? (
      <h4 className="campuses-groups-header">{filtersHeader}</h4>
    ) : null;

  const navigation = showNavigation ? (
    <MonthSelector onChangeDate={onChangeDate} />
  ) : null;

  const upcomingNavigation = showUpcomingNavigation ? (
    <MonthSelector onChangeDate={onUpcomingNavigationChange} />
  ) : null;

  /**
   * If it is a single event, means that we cannot display selected date which is current date by default.
   * It may cause a wrong month header label display. E.g. Event happens in July and current month is June.
   * This way it will show June until events are loaded. If it's a single event, wait for that event to load
   * before displaying header.
   * @type {JSX.Element|null}
   */
  const monthSelector =
    (!singleEvent || (singleEvent && events.length)) && showMonthSelector ? (
      <div className="month-selector">
        {navigation}
        <CalendarHeaderLabel
          currentDate={currentDate}
          show={showMonthHeader}
          events={events}
          eventsNumberOption={kindOfNumberOfEvents}
          showUpcomingNavigation={showUpcomingNavigation}
        />

        {upcomingNavigation}
      </div>
    ) : null;

  const loadingIndicator = filters.fetchCalendarFilters ? (
    <div className={"loading-indicator"}>
      <LoadingIndicator />
    </div>
  ) : null;

  const selectors = !loadingIndicator ? (
    <CalendarHeaderSelectors
      onChangeFilter={onChangeFilter}
      selectedFilters={selectedFilters}
    />
  ) : null;

  return (
    <div>
      <div className={"dc-detailed-list--calendar-header-top-controls"}>
        <SearchBar onSearch={onSearch} />
        <ShareCalendarButton
          title={calendarPublicName(calendar.model)}
          url={`${process.env.BASE_URL}/c/${calendar.model.slug}`}
          className={"dc-detailed-list--calendar-share-button"}
        />
        <SubscribeCalendarButton
          title={calendarPublicName(calendar.model)}
          url={`${process.env.BASE_URL}/c/${calendar.model.slug}`}
          RSSUrl={`${process.env.BASE_URL}/feeds/calendar/${calendar.model.uuid}.rss`}
          iCalPath={`${calendar.model.integration.uuid}/${calendar.model.uuid}`}
          className={"dc-detailed-list--calendar-subscribe-button"}
        />
      </div>
      <SelectorGroup show={true}>
        {loadingIndicator}
        {selectors}
      </SelectorGroup>
      {topHeader}
      {monthSelector}
    </div>
  );
};

export default CalendarHeader;
