/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/10/2018.
 */

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";

import NewCalendarPreview from "./NewCalendarPage/NewCalendarPreview";
import MandatoryInformation from "./CalendarPage/SettingsTabs/MandatoryInformation";
import NewWidgetWizard from "./../component/NewWidgetWizard";
import { store } from "../store";
import { useCalendarStore } from "../../../hooks/redux/calendar";
import SidebarTab from "../component/WidgetSettings/SidebarTab";
import SuccessAlert from "../component/SuccessAlert";
import ApiError from "../component/ApiError";
import { calendarSettingsValidator, updateJquery } from "../tools";
import { isCTAllowed } from "../../../builder";
import { CALENDAR_TEMPLATES_PLANS, CT, IT, PLANS } from "../../../consts";
import { WT } from "../consts/widget";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../helpers/organization";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { useCalendarsStore } from "../../../hooks/redux/calendars";
import { useEventsStore } from "../../../hooks/redux/events";
import appConfig from "../../../appConfig";
import classNames from "classnames";

const IntegrationDefaultFilters = {
  [IT.google]: null,
  [IT.ccb]: null,
  [IT.breeze]: null,
  [IT.pco]: {
    conjunction: "and",
    groups: [
      {
        conjunction: "and",
        rules: [
          {
            field: {
              value: "approval_status",
              label: "Approval Status",
            },
            value: "A",
            label: "Approved",
            operator: {
              value: "is",
              label: "is",
            },
          },
        ],
      },
    ],
  },
};

const initialState = {
  calendarModel: {
    name: "",
    available: 0,
    integration_uuid: 0,
    template: "",
    show_private_events: 0,
    featured_events_limit: 0,
  },
  errors: {},
};

const NewCalendarPage = (props) => {
  const sidebarOverlayRef = useRef(null);
  const chatSidebarOverlayRef = useRef(null);
  const { calendar } = useCalendarStore();
  const { calendars } = useCalendarsStore();
  const { events } = useEventsStore();
  const { integrations } = useIntegrationsStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const loading = calendar.update || calendar.fetch || calendar.delete;
  const subscription = hasSubscription(currentOrganization);

  const { updateCalendar, setCalendar } = useCalendarStore();

  const history = useHistory();

  const [state, setState] = useState(JSON.parse(JSON.stringify(initialState)));

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const data = {
      ...state,
    };

    data.calendarModel[fieldName] = value;

    data.errors = calendarSettingsValidator(data.calendarModel);

    setState(data);
  };

  useEffect(() => {
    if (calendar.fetchSuccess) {
      if (!calendar.model.integration) {
        history.push("/dashboard");
      }
    }

    if (calendars.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }

    if (events.fetchSuccess) {
      setTimeout(() => window.$('[data-toggle="tooltip"]').tooltip(), 500);
    }
  }, [calendar.fetchSuccess, calendars.fetchSuccess, events.fetchSuccess]);

  useEffect(() => {
    window.$(".chat-main-row").animate({ scrollTop: 0 }, 200);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state.calendarModel.template]);

  useEffect(() => {
    return () => setCalendar(null);
  }, []);

  /**
   *
   */
  const onSubmit = async () => {
    const defaultUISettings = availableCalendars.find(
      (i) => i.slug === state.calendarModel.template
    ).defaultDesignSettings;

    const data = { ...state.calendarModel };
    data.widget_settings = { design: defaultUISettings };

    // for PCO, turn private events on by default
    const selectedIntegration = integrations.collection.find(
      (i) => i.uuid === state.calendarModel.integration_uuid
    );

    if (selectedIntegration && selectedIntegration.type === IT.pco) {
      data.show_private_events = 1;
    }

    // apply filter defaults
    if (IntegrationDefaultFilters[selectedIntegration.type]) {
      data.filter_settings = {
        filters: IntegrationDefaultFilters[selectedIntegration.type],
        featured_filters: IntegrationDefaultFilters[selectedIntegration.type],
      };
    }
    await updateCalendar(null, data);

    const storeState = store.getState();

    if (storeState.calendar.updateSuccess) {
      await setCalendar(null);

      history.push("/calendar/" + storeState.calendar.model.uuid);
    }
  };

  /**
   *
   * @param calendarType
   */
  const onSelectedNewCalendarType = useCallback(
    (calendarType) => () => {
      setCalendar({});
      const data = {
        ...state,
      };
      data.calendarModel.template = calendarType;
      setState(data);
    },
    [state.calendarModel]
  );

  /**
   *
   * @param calendarType
   */
  const cancelSelection = useCallback(() => {
    setCalendar({});
    setState(JSON.parse(JSON.stringify(initialState)));
    if (sidebarOverlayRef?.current) {
      sidebarOverlayRef.current?.classList?.remove("opened");
    }
    if (chatSidebarOverlayRef?.current) {
      chatSidebarOverlayRef.current?.classList?.remove("opened");
    }
  }, [state.calendarModel]);

  const availableCalendars = [
    {
      title: "Detailed list",
      description: (
        <span>
          Looking for a way to better sell your events? Consider this option.
        </span>
      ),
      slug: CT.detailedList,
      thumbnailUrl: "/img/calendar_type/detailed_list_icon.png",
      features: [
        "Can put CTA links to RSVP/Sign-up Forms on main page.",
        "More visible highlight of Featured Events.",
      ],
      forPlans: CALENDAR_TEMPLATES_PLANS[CT.detailedList],
      defaultDesignSettings: {
        generalOpenDetailsModal: 1,
        generalExpandDetailAccording: 0,
        calendarShowShare: 1,
      },
      previewUrl: `${appConfig.homepage}/demo-calendars/detailed-list/`,
    },
    {
      title: "Cards (Slider)",
      description: (
        <span>
          Cards Slider is a carousel design with a modern and familiar
          experience allowing a great way to show a lot in a little space.
        </span>
      ),
      slug: CT.cardSlider,
      thumbnailUrl: "/img/calendar_type/card_slider_icon.png",
      features: [
        "Ideal for those who want a carousel or presentation design.",
        "Works great for the web and especially on mobile phones",
      ],
      forPlans: CALENDAR_TEMPLATES_PLANS[CT.cardSlider],
      defaultDesignSettings: {
        generalOpenDetailsModal: 1,
        generalExpandDetailAccording: 0,
        eventsNumberOption: "upcoming-limit",
        eventsNumber: 3,
        calendarShowShare: 1,
      },
      previewUrl: `${appConfig.homepage}/demo-calendars/slider/`,
    },
    {
      title: "Cards (Grid)",
      description: (
        <span>
          Cards (Grid) offers a modern, easy template modeled on social media
          type cards. <strong>Especially suited for desktop viewing.</strong>
        </span>
      ),
      slug: CT.card,
      thumbnailUrl: "/img/calendar_type/card_view_icon.png",
      features: [
        "At-a glance, multiple event full desktop display.",
        "Creates excitement encouraging people to participate.",
      ],
      forPlans: CALENDAR_TEMPLATES_PLANS[CT.card],
      defaultDesignSettings: {
        generalOpenDetailsModal: 1,
        generalExpandDetailAccording: 0,
        infiniteScroll: 1,
        calendarShowShare: 1,
      },
      previewUrl: `${appConfig.homepage}/demo-calendars/cardsgrid/`,
    },
    {
      title: "Cards (List)",
      description: (
        <span>
          Cards (List) offers a modern, easy template modeled on social media
          type cards. <strong>Especially suited for mobile viewing.</strong>
        </span>
      ),
      slug: CT.cardList,
      thumbnailUrl: "/img/calendar_type/card_view_list_icon.png",
      features: [
        "Compact view can include both image and details.",
        "Layout looks great on smaller digital screens.",
      ],
      forPlans: CALENDAR_TEMPLATES_PLANS[CT.cardList],
      defaultDesignSettings: {
        generalOpenDetailsModal: 1,
        generalExpandDetailAccording: 0,
        infiniteScroll: 1,
        calendarShowShare: 1,
      },
      previewUrl: `${appConfig.homepage}/demo-calendars/cardslist/`,
    },
    {
      title: "Monthly View",
      description: (
        <span>
          This view gives your people a better picture of everything happening
          during the month.
        </span>
      ),
      slug: CT.monthly,
      thumbnailUrl: "/img/calendar_type/fullcal_icon.png",
      features: [
        "Desktop: At-a-glance overview of recurring and featured events.",
        "Mobile: Easy access to daily events with minimal scrolling.",
      ],
      forPlans: CALENDAR_TEMPLATES_PLANS[CT.monthly],
      defaultDesignSettings: {
        calendarShowShare: 1,
      },
      previewUrl: `${appConfig.homepage}/demo-calendars/fullcalendar/`,
    },
    {
      title: "Weekly View",
      description: (
        <span>Reduce the overload by focusing on just this weeks events.</span>
      ),
      slug: CT.weekly,
      thumbnailUrl: "/img/calendar_type/weekly_view_icon.png",
      features: [
        "Easier for your people to plan their attendance.",
        "Choice of 3 displays: list, pills, and stacks.",
      ],
      forPlans: CALENDAR_TEMPLATES_PLANS[CT.weekly],
      defaultDesignSettings: {
        calendarShowShare: 1,
      },
      previewUrl: `${appConfig.homepage}/demo-calendars/weeklyview/`,
    },
    {
      title: "Events List",
      description: (
        <span>
          This view gives a straightforward, uncluttered listing of your monthly
          happenings.
        </span>
      ),

      slug: CT.eventList,
      thumbnailUrl: "/img/calendar_type/events_list_icon.png",
      features: [
        "Keep it simple to focus on the events themselves.",
        "Recurring and Featured Badges show up well on desktop and mobile.",
      ],
      forPlans: CALENDAR_TEMPLATES_PLANS[CT.eventList],
      defaultDesignSettings: {
        infiniteScroll: 1,
        calendarShowShare: 1,
      },
      previewUrl: `${appConfig.homepage}/demo-calendars/eventlist/`,
    },
    {
      title: "Tiles",
      description: (
        <span>
          A compact way to show your events. Can work as a sidebar widget or a
          featured events section.
        </span>
      ),
      slug: CT.bubble,
      thumbnailUrl: "/img/calendar_type/tiles_icon.png",
      features: [
        "Change the size and shape of the tiles to fit a variety of page layouts.",
        "Use image + CTA for a shortcut to your contact person or sign-up forms.",
      ],
      forPlans: CALENDAR_TEMPLATES_PLANS[CT.bubble],
      defaultDesignSettings: {
        generalOpenDetailsModal: 1,
        generalExpandDetailAccording: 0,
        calendarShowShare: 1,
      },
      // previewUrl: "https://display.church/demo-calendars/detailed-list/",
    },
  ];

  const settingsTabsOptions = [];

  settingsTabsOptions.push({
    label: "Setup",
    id: "setup_tab",
    additionalClassName: "active show",
  });

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const footer = !loading ? (
    <div>
      <SuccessAlert>
        {calendar.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <ApiError
        error={calendar.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      <div className="d-flex justify-content-end gap-xs">
        <button
          type="button"
          data-reff="#chat_sidebar"
          onClick={cancelSelection}
          className="btn btn-secondary d-xs-block d-lg-none sidenav_btn  task-chat nav-link "
        >
          Cancel
        </button>
        <button
          disabled={loading}
          type="submit"
          className="btn btn-primary"
          onClick={onSubmit}
        >
          Create
        </button>
      </div>
    </div>
  ) : null;

  const hasCalendarTypeSelected = state.calendarModel.template.length
    ? true
    : false;

  /*// if selected calendar in state calendarModel template isn't allowed for user's plan, then flag should be set to false
  const isCalendarTypeAllowedForPlan =
    hasCalendarTypeSelected &&
    CALENDAR_TEMPLATES_PLANS[state.calendarModel.template].indexOf(
      subscription.code || PLANS.free
    ) > -1
      ? true
      : false;*/

  const setupTab = (
    <SidebarTab
      id="setup_tab"
      show={true}
      footer={hasCalendarTypeSelected ? footer : null}
    >
      <NewWidgetWizard
        hasSelectedWidget={state.calendarModel.template}
        hasEnteredWidgetName={state.calendarModel.name.length >= 3}
        hasSelectedIntegration={state.calendarModel.integration_uuid != "0"}
        widgetName={"Calendar"}
        widgetType={WT.calendar}
      />

      {hasCalendarTypeSelected ? (
        <MandatoryInformation
          calendarModel={state.calendarModel || {}}
          onUpdateField={onUpdateField}
          disabled={loading}
          formErrors={state.errors}
          integrations={integrations.collection}
          expanded={true}
          showSummary={false}
          /*
          isCalendarTypeAllowedForPlan={isCalendarTypeAllowedForPlan}
*/
        />
      ) : null}
    </SidebarTab>
  );

  const calendarOptions = availableCalendars.map((i, idx) => {
    const canSelect = isCTAllowed(subscription.code || PLANS.free, i.forPlans);
    return (
      <NewCalendarPreview
        key={i.slug}
        {...i}
        onSelect={onSelectedNewCalendarType(i.slug)}
        subscriptionPlanNames
        canSelect={canSelect}
        isSelected={state.calendarModel.template === i.slug}
      />
    );
  });

  return (
    <PageTemplate
      title={"Calendars"}
      sidebarId="calendar"
      icon={"event"}
      className={"page-wrapper-with-white-bg"}
    >
      <div className="chat-main-row">
        <div className="chat-main-wrapper">
          <div className="col-xs-12 message-view task-view">
            <div className="chat-window">
              <div className="chat-contents calendar-background">
                <div className="chat-content-wrap">
                  <div className="chat-wrap-inner">
                    <div className="chat-box">
                      <div className="chats">
                        <div className="calendar-preview-container">
                          <div className="container container-fluid">
                            <div className="row">
                              <div className="col-sm-12">
                                <h4 className="page-title">&nbsp;</h4>
                              </div>
                            </div>
                            <div className="">
                              <h2>New Calendar Setup</h2>
                              <p>
                                Start by selecting a calendar type, adding a
                                name, and selecting the integration.
                              </p>
                              <div className="row">{calendarOptions}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 message-view chat-profile-view chat-sidebar"
            id="chat_sidebar"
            ref={chatSidebarOverlayRef}
          >
            <div className="chat-window video-window">
              <div className="fixed-header">
                <ul className="nav nav-tabs nav-tabs-bottom">{settingsTabs}</ul>
              </div>
              <div className="tab-content chat-contents">{setupTab}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sidebar-overlay"
        data-reff="#chat_sidebar"
        ref={sidebarOverlayRef}
      />
    </PageTemplate>
  );
};

export default NewCalendarPage;
