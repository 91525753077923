/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/05/2024
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getEndOfMonth } from "../../../../../../helpers/date";
import classNames from "classnames";

const valueToRange = (value) => {
  if (!value) {
    return [null, null];
  }

  if (value.indexOf(":") === -1) {
    return [null, null];
  }

  return value.split(":");
};

const getInitialDateRange = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  const day = date.getDate();

  return `${year}-${month}-${day}:${year}-${month}-${getEndOfMonth(
    date
  ).getDate()}`;
};

const DateRangeInput = (props) => {
  const [range, setRange] = useState(
    props.disabled
      ? [null, null]
      : valueToRange(props.customInputValue || getInitialDateRange())
  );

  const [outOfRangeWarning, setOutOfRangeWarning] = React.useState(false);

  useEffect(() => {
    if (!props.disabled && !range[0] && !range[1]) {
      const initialRange = getInitialDateRange();
      props.updateDesignProp([
        {
          field: props.name,
          value: initialRange,
        },
      ]);

      setRange(valueToRange(initialRange));
    } else if (props.disabled && outOfRangeWarning) {
      setOutOfRangeWarning(false);
    }
  }, [props.disabled]);

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onChange = useCallback(
    (index) => (event) => {
      const newRange = [...range];
      newRange[index] = event.target.value;
      setRange(newRange);

      props.updateDesignProp([
        {
          field: props.name,
          value: `${newRange[0]}:${newRange[1]}`,
        },
      ]);

      if (newRange[0] && newRange[1]) {
        const startDate = new Date(newRange[0]);
        const endDate = new Date(newRange[1]);
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 31) {
          setOutOfRangeWarning(true);
        } else {
          setOutOfRangeWarning(false);
        }
      }
    },
    [props.updateDesignProp, range]
  );

  const warning =
    outOfRangeWarning && !props.disabled ? (
      <div className="invalid-feedback">
        The selected date range exceeds the maximum allowed duration of 31 days.
      </div>
    ) : null;

  return (
    <React.Fragment>
      <input
        type="date"
        step={1}
        className={classNames("form-control form-control-sm", {
          "is-invalid": outOfRangeWarning,
        })}
        disabled={props.disabled}
        value={!props.disabled ? range[0] : ""}
        onChange={onChange(0)}
      />{" "}
      -{" "}
      <input
        type="date"
        step={1}
        className={classNames("form-control form-control-sm", {
          "is-invalid": outOfRangeWarning,
        })}
        disabled={props.disabled}
        value={!props.disabled ? range[1] : ""}
        onChange={onChange(1)}
      />
      {warning}
    </React.Fragment>
  );
};

DateRangeInput.defaultProps = {};

DateRangeInput.propTypes = {};

export default DateRangeInput;
