import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";

/**
 *
 * @param {Boolean} completed
 * @param {string} linkUrl
 * @param {string="_self"} linkTarget
 * @param {object|string} children
 * @returns {Element}
 * @constructor
 */
const TaskItem = ({ completed, linkUrl, children, linkTarget = "_self" }) => {
  const link =
    linkUrl && linkUrl.charAt(0) === "/" ? (
      <Link to={linkUrl}>{children}</Link>
    ) : (
      <a href={linkUrl} target={linkTarget}>
        {children}
      </a>
    );

  const label = completed || !linkUrl ? children : link;

  return (
    <li className={classnames("task", { completed })}>
      <div className="task-container">
        <span className="task-action-btn task-check">
          <span className="action-circle large complete-btn">
            <i className="material-icons">check</i>
          </span>
        </span>
        <span className="task-label">{label}</span>
      </div>
    </li>
  );
};

export default TaskItem;
