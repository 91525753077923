/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/12/2020.
 */

import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import PropTypes from "prop-types";
import EventDetails from "./EventDetails";
import EventHeader from "./EventHeader";
import classnames from "classnames";
import useScrollToElement from "../../../../hooks/useScrollToElement";
import PoweredBy from "./../PoweredBy";
import sleep from "../../../../helpers/sleep";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import { useTranslation } from "react-i18next";
import CalendarLinkingContext from "../../../../contexts/CalendarLinkingContext";

const RowModal = React.memo((props) => {
  const { event, calendarSlug } = props;

  const [modalDom] = useState(document.createElement("div"));
  const { i18n } = useTranslation();
  const widgetSettings = useContext(WidgetSettingsContext);
  const linkingContext = useContext(CalendarLinkingContext);

  const [VFXState, setVFXState] = useState({
    showWrapper: document.querySelector("#rev-modal-event-loader")
      ? true
      : false,
    showContainer: document.querySelector("#rev-modal-event-loader")
      ? true
      : false,
  });

  useScrollToElement(
    props.onOpenScrollToSelector ? `#${props.onOpenScrollToSelector}` : null
  );

  useEffect(() => {
    document.body.appendChild(modalDom);

    return () => {
      document.body.removeChild(modalDom);
    };
  }, []);

  useEffect(() => {
    window.postMessage(
      { type: "rev-item-loaded", widgetUUID: props.calendarUUID },
      "*"
    );
  }, []);

  // useEffect(() => {
  //   const loaderModal = document.querySelector(
  //     `#modal-event-wrapper-rev-loader`
  //   );
  //
  //   if (loaderModal) {
  //     document.body.removeChild(loaderModal.parentNode);
  //   }
  // }, []);

  const useEventAccent =
    widgetSettings["generalUseEventAccent"] * 1 ? true : false;

  const showHeaderSummary = Number.parseInt(
    widgetSettings["generalShowEventSummary"]
  )
    ? true
    : false;

  /**
   *
   * @param e
   */
  const onModalAreaClick = (e) => {
    const eventContainer = document.querySelector("#modal-event-container");
    const sharePopupContainer = document.querySelector("#dc-share-popup");

    if (!sharePopupContainer && !eventContainer.contains(e.target)) {
      e.stopPropagation();
      onEventsRowClick();
    }
  };

  /**
   *
   */
  const onEventsRowClick = () => {
    setStatePromise(setVFXState, {
      showWrapper: false,
      showContainer: false,
    })
      .then(() => sleep(150))
      .then(() => {
        document.body.style.overflow = "auto";
        linkingContext.onCloseOpenedEventsDetails();
        if (props.closeOpenedEventsDetails) {
          props.closeOpenedEventsDetails(
            props.event.slug == props.expandedRowSlug ? "" : props.event.slug
          );
        }
      });
  };

  /**
   *
   * @param setState
   * @param state
   * @returns {Promise<void>}
   */
  const setStatePromise = (setState, state) => {
    setState(state);
    return Promise.resolve();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    if (!(VFXState.showWrapper && VFXState.showContainer)) {
      setStatePromise(setVFXState, { showWrapper: true, showContainer: false })
        .then(() => sleep(150))
        .then(() => {
          setStatePromise(setVFXState, {
            showWrapper: true,
            showContainer: true,
          });
        });
    }
  }, []);

  const watermark = props.showWatermark ? (
    <div className={"d-flex justify-content-center"}>
      <PoweredBy />
    </div>
  ) : null;

  return ReactDOM.createPortal(
    <div
      className={classnames("event-modal--wrapper", props.calendarID, {
        show: VFXState.showWrapper,
      })}
      id="modal-event-wrapper"
      onClick={onModalAreaClick}
      lang={i18n.language}
    >
      <div
        className={classnames("event-modal--container", {
          show: VFXState.showContainer,
          _highlights: widgetSettings["cFeaturedLabel"], // it should determine on a different basis, like design settings name
        })}
        id={"modal-event-container"}
      >
        <div className={"rev-calendar-closer-container"}>
          <div
            className="event-modal--close-container"
            onClick={onEventsRowClick}
          >
            <i className="material-icons" aria-hidden={true}>
              close
            </i>
          </div>
        </div>
        {/*<>// TODO use event accent</>*/}
        <EventHeader
          embedded={props.embedded}
          event={event}
          useEventAccent={useEventAccent}
          showSummary={showHeaderSummary}
        />

        <EventDetails
          event={event}
          calendarSlug={calendarSlug}
          embedded={props.embedded}
          showDetails={props.showDetails}
          ccbAddress={props.ccbAddress}
          onRSVPEvent={props.onRSVPEvent}
          loading={props.loading}
          error={props.error}
          expandedRowSlug={props.expandedRowSlug}
        />
      </div>
      {watermark}
    </div>,
    modalDom
  );
});

RowModal.defaultProps = {
  showDetails: false,
  showWatermark: false,
};

RowModal.propTypes = {
  showDetails: PropTypes.bool,
  calendarSlug: PropTypes.string,
  calendarID: PropTypes.string,
  embedded: PropTypes.bool,
  closeOpenedEventsDetails: PropTypes.func,
  event: PropTypes.object.isRequired,
  ccbAddress: PropTypes.string,
  onRSVPEvent: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  expandedRowSlug: PropTypes.string,
  expandAsAccordion: PropTypes.bool,
  expandAsModal: PropTypes.bool,
  onOpenScrollToSelector: PropTypes.string,
  showWatermark: PropTypes.bool,
};
//Row.whyDidYouRender = true
export default RowModal;
