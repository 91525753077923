/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022
 */

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { copyTextToClipboard } from "../../tools";
import classnames from "classnames";
import { IT } from "../../../../consts";
import HamburgerDropdown from "../UI/HamburgerDropdown";
import PublishDropdown from "../UI/PublishDropdown";

const TableRow = (props) => {
  const [clipboardCopied, setClipboardCopied] = useState(false);

  useEffect(() => {
    if (clipboardCopied) {
      setTimeout(() => setClipboardCopied(false), 300);
    }
  }, [clipboardCopied]);

  /**
   *
   */
  const onUserAction = (callback) => (e) => {
    if (e) e.preventDefault();

    callback(props.widget);

    return false;
  };

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const onCopyToClipboard = (e) => {
    e.preventDefault();
    copyTextToClipboard(props.shareableUrl(props.widget.slug));
    setClipboardCopied(true);

    return false;
  };

  const {
    uuid,
    name,
    integration,
    modified,
    available,
    template,
    widget_type,
    disabled_date,
  } = props.widget;

  const disabled = props.disabled;

  const disabledAlert = disabled_date ? (
    <div
      data-toggle="tooltip"
      data-placement="top"
      data-trigger={"hover"}
      title={"This widget has been disabled due to plan downgrade."}
      className={"cursor-pointer"}
    >
      <i className={"material-icons design-tooltip text-danger"}>warning</i>
    </div>
  ) : null;

  const editUrl = integration
    ? `/${widget_type}/${uuid}`
    : `/${widget_type}/attach-integration/${uuid}`;

  const menuOptions = useMemo(
    () => [
      {
        label: "Edit",
        icon: "edit",
        href: editUrl,
      },
      {
        label: "Clone",
        icon: "content_copy",
        onClick: onUserAction(props.onClone),
      },
      {
        label: "Copy shareable link",
        icon: "add_link",
        onClick: onCopyToClipboard,
      },
      {
        label: "Delete",
        icon: "delete",
        onClick: onUserAction(props.onDelete),
      },
    ],
    [props.onEdit, props.onClone, props.onDelete, onCopyToClipboard]
  );

  return (
    <tr
      className={classnames({
        "table-success blinker": clipboardCopied,
        "table-secondary": uuid == props.widgetEditUUID,
      })}
    >
      <td>{disabledAlert}</td>
      <td>
        <Link to={editUrl}>{name}</Link>
      </td>
      <td>
        {integration ? (
          integration.type.toUpperCase() +
          (integration.type !== IT.google ? " (Premium)" : "")
        ) : (
          <span className={"badge badge-danger"}>Disconnected</span>
        )}
      </td>

      <td>{<props.nameComponent type={template} />}</td>

      <td>
        <PublishDropdown
          available={available}
          onChangeStatus={onUserAction(props.onChangeStatus)}
          disabled={disabled}
          customIcon={disabledAlert}
        />
      </td>
      <td align="center">
        {modified ? moment(modified * 1000).format("YYYY-MM-DD HH:mm") : "-"}
      </td>
      <td className="text-right">
        <HamburgerDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  widget: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    available: PropTypes.bool.isRequired,
    disabled_date: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  widgetEditUUID: PropTypes.string,
  onChangeStatus: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  nameComponent: PropTypes.func.isRequired,
};

export default TableRow;
