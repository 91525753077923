/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/11/2024
 */

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getUploadResourceUrl } from "../../helpers/url";

const caldavServerHost = `caldav.${process.env.BASE_HOST}`;

const Apps = [
  {
    label: "Apple",
    icon: "apple.png",
    url: (path) => `webcal://${caldavServerHost}/${path}`,
  },
  {
    label: "Google",
    icon: "google.png",
    url: (path) =>
      "https://www.google.com/calendar/render?cid=" +
      encodeURIComponent(`webcal://${caldavServerHost}/${path}`),
  },
  {
    label: "Outlook 365",
    icon: "outlook365.png",
    url: (path, title) =>
      "https://outlook.office.com/owa?path=%2Fcalendar%2Faction%2Fcompose&rru=addsubscription&url=" +
      encodeURIComponent(`webcal://${caldavServerHost}/${path}`) +
      `&name=${title}`,
  },
  {
    label: "Download ICS file",
    icon: "ics_file.png",
    url: (path) => `https://${caldavServerHost}/${path}`,
    offline: true,
  },
];

/**
 *
 * @param {String} iCalPath
 * @param {String} title
 * @param {String} [containerClassName="dc-subscribe-popup--add-to-calendar-container"]
 * @param {String} [itemClassName="dc-subscribe-popup--add-to-calendar-link"]
 * @param {String} [wrapperClassName="dc-subscribe-popup--add-to-calendar-link"]
 * @param {Boolean} [showLabel=true]
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const AddToCalendar = ({
  iCalPath,
  title,
  containerClassName = "dc-subscribe-popup--add-to-calendar-container",
  itemClassName = "dc-subscribe-popup--add-to-calendar-link",
  wrapperClassName = "dc-subscribe-popup--add-to-calendar-links",
  showLabel = true,
}) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      Apps.map((i) => (
        <a
          key={i.label}
          href={i.url(iCalPath, title)}
          className={itemClassName}
          target={"_revICAL"}
        >
          <img
            src={getUploadResourceUrl(`img/calendar_apps/${i.icon}`)}
            alt={t(i.label)}
          />
          {showLabel && t(i.label)}{" "}
          {i.offline && (
            <span className={"dc-subscribe-popup_offline"}>(Offline)</span>
          )}
        </a>
      )),
    [iCalPath]
  );

  if (!iCalPath) {
    return null;
  }

  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>{options}</div>
    </div>
  );
};

export default AddToCalendar;
