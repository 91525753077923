/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { CT } from "../../../consts";

export const definitions = {
  title: "Weekly view",
  icon: "brush",
  groups: [
    {
      label: "Days",
      options: [
        {
          title: "Week day",
          templates: [CT.weekly],
          name: "wwWeekDay",
          defaultValue: "#949494",
          style: [
            {
              selector: ".weekly-calendar .weekly-cal--day-container",
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Week day background",
          templates: [CT.weekly],
          name: "wwWeekDayBg",
          defaultValue: "#fbfbfb",
          style: [
            {
              selector: ".weekly-calendar .weekly-cal--day-container",
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Week day hovered",
          templates: [CT.weekly],
          name: "wwWeekDayHover",
          defaultValue: "#949494",
          style: [
            {
              selector: [".weekly-calendar .weekly-cal--day-container:hover"],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Week day hovered bg",
          templates: [CT.weekly],
          name: "wwWeekDayHoverBg",
          defaultValue: "#f7f7f7",
          style: [
            {
              selector: [".weekly-calendar .weekly-cal--day-container:hover"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected day",
          templates: [CT.weekly],
          name: "wwWeekDaySelected",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [
                ".weekly-calendar .weekly-cal--day-container.selected",
                ".weekly-calendar .weekly-cal--day-container.selected:hover",
              ],
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "wwEventsLocation",
            value: "events_list",
            defaultValue: "events_list",
            message:
              'This option requires "Weekly View Events as Events List" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected day background",
          templates: [CT.weekly],
          name: "wwWeekDaySelectedBg",
          defaultValue: "#f79191",
          style: [
            {
              selector: [
                ".weekly-calendar .weekly-cal--day-container.selected",
                ".weekly-calendar .weekly-cal--day-container.selected:hover",
              ],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "wwEventsLocation",
            value: "events_list",
            defaultValue: "events_list",
            message:
              'This option requires "Weekly View Events as Events List" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Frame",
      options: [
        {
          title: "Color",
          templates: [CT.weekly],
          name: "wwFrameColor",
          defaultValue: "#f1f1f1",
          style: [
            {
              selector: [".weekly-calendar .weekly-cal--day-wrapper"],
              property: ["border-color"],
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Current day accent",
          templates: [CT.weekly],
          name: "wwAccent",
          defaultValue: "#f79191",
          style: [
            {
              selector: [
                ".weekly-calendar .weekly-cal--day-container.today::before",
              ],
              property: ["background-color"],
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Events",
      options: [
        {
          title: "Indication",
          templates: [CT.weekly],
          name: "wwEventIndication",
          defaultValue: "#4bb5d8",
          style: [
            {
              selector: [".weekly-calendar .weekly-cal--day-events-indicator"],
              property: ["background-color"],
            },
          ],
          tooltip: null,
          dependency: {
            field: "wwShowEventsIndication",
            value: 1,
            defaultValue: 1,
            message:
              'This option requires "Show Events indication" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Pills",
      options: [
        {
          title: "Color",
          templates: [CT.weekly],
          name: "wwPillColor",
          defaultValue: "#fff",
          style: [
            {
              selector: [
                ".weekly-calendar.pill .weekly-calendar--container a .weekly-cal--day__event",
                ".weekly-calendar.pill .weekly-calendar--container .weekly-cal--day__event",
                ".weekly-calendar.pill .weekly-calendar--container .weekly-cal--day__event .time",
              ],
              property: ["color"],
            },
          ],
          tooltip: null,
          dependency: {
            field: "wwEventsLocation",
            value: "pills",
            defaultValue: "events_list",
            message:
              'This option requires "Weekly View Events as Pills" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background",
          templates: [CT.weekly],
          name: "wwPillColorBG",
          defaultValue: "#4bb5d8",
          style: [
            {
              selector: [
                ".weekly-calendar.pill .weekly-calendar--container .weekly-cal--day__event",
              ],
              property: ["background-color"],
            },
          ],
          tooltip: null,
          dependency: [
            {
              field: "wwEventsLocation",
              value: "pills",
              defaultValue: "events_list",
              message:
                'This option requires "Weekly View Events as Pills" behavior to be turned on',
            },
            {
              field: "generalUseEventAccent",
              value: 0,
              defaultValue: 1,
              message:
                'This option is overwritten by "Use calendar color as accent color" option',
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Stack",
      options: [
        {
          title: "Color",
          templates: [CT.weekly],
          name: "wwStackColor",
          defaultValue: "#949494",
          style: [
            {
              selector: [
                ".weekly-calendar.stack .weekly-calendar--container .weekly-cal--day__event",
                ".weekly-calendar.stack .weekly-calendar--container .weekly-cal--day__event .time",
              ],
              property: ["color"],
            },
          ],
          tooltip: null,
          dependency: {
            field: "wwEventsLocation",
            value: "stack",
            defaultValue: "events_list",
            message:
              'This option requires "Weekly View Events as Stack" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Accent color",
          templates: [CT.weekly],
          name: "wwStackAccent",
          defaultValue: "#4bb5d8",
          style: [
            {
              selector: [
                ".weekly-calendar.stack .weekly-calendar--container .weekly-cal--day__event",
              ],
              property: ["border-color"],
            },
          ],
          tooltip: null,
          dependency: [
            {
              field: "wwEventsLocation",
              value: "stack",
              defaultValue: "events_list",
              message:
                'This option requires "Weekly View Events as Stack" behavior to be turned on',
            },
            {
              field: "generalUseEventAccent",
              value: 0,
              defaultValue: 1,
              message:
                'This option is overwritten by "Use calendar color as accent color" option',
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Navigation",
      options: [
        {
          title: "This week button",
          templates: [CT.weekly],
          name: "wwThisWeekBtn",
          defaultValue: "#b7b7b7",
          style: [
            {
              selector: [".weekly-calendar .date-range-selector__this-week"],
              property: ["color"],
            },
          ],
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This option requires "Allow changing weeks" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "This week button border",
          templates: [CT.weekly],
          name: "wwThisWeekBtnBorder",
          defaultValue: "#e2e0e2",
          style: [
            {
              selector: [".weekly-calendar .date-range-selector__this-week"],
              property: ["border-color"],
            },
          ],
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This option requires "Allow changing weeks" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "This week button background",
          templates: [CT.weekly],
          name: "wwThisWeekBtnBG",
          defaultValue: "#e2e0e2",
          style: [
            {
              selector: [".weekly-calendar .date-range-selector__this-week"],
              property: ["background-color"],
            },
          ],
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This option requires "Allow changing weeks" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Month label background",
          templates: [CT.weekly],
          name: "wwMonthLabelBg",
          defaultValue: "#f3f3f3",
          style: [
            {
              selector: [".weekly-calendar .new-month-label"],
              property: ["background-color"],
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
