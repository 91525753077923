/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import localstorage from "local-storage";
/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const _fetchEvents = (
  actionType,
  calendarUUID,
  from,
  to = null,
  limit = null,
  page = null,
  filters = [],
  preservePreviousEvents = false,
  featuredOnly = 0,
  params = {}
) => {
  return async (dispatch, getState) => {
    const _from = from instanceof Date ? from : new Date(`${from}T00:00:00`);

    // console.log("from", from, _from);
    const postData = {
      calendar_uuid: calendarUUID,
      start_date: _from.getStartOfDay().getUnixTime(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      page,
      limit,
      ...params,
    };

    if (!page) {
      const _to = to instanceof Date ? to : new Date(`${to}T00:00:00`);
      postData.end_date = _to.getEndOfDay().getUnixTime();
    }

    if (filters && filters.length) {
      postData["calendar_filters"] = filters;
    }

    if (featuredOnly) {
      postData["featured"] = 1;
    }

    const queryParamsData = {
      page,
      limit,
      uuid: calendarUUID,
    };

    if (params?.internal === 1) {
      queryParamsData.internal = 1;
      delete postData.internal;
    }

    const queryParams = AxiosConfig.objectToURLQuery(queryParamsData);

    const url = "/api4/events/get_list.json?" + queryParams;

    const requestUri = AxiosConfig.objectToURLQuery(postData);
    dispatch({
      type: actionType,
      requestUri,
      preservePreviousEvents,
    });

    if (getState().widgetItemsCache.collection[requestUri]) {
      // cache hit
      return;
    }
    return await axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        postData,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: `${actionType}_FULFILLED`,
          payload: response.data,
          preservePreviousEvents,
          requestUri: AxiosConfig.objectToURLQuery(postData),
        });
      })
      .catch(function (error) {
        dispatch({
          type: `${actionType}_REJECTED`,
          payload: error,
          preservePreviousEvents,
        });
      });
  };
};

/**
 *
 * @param calendarUUID
 * @param from
 * @param to
 * @param limit
 * @param page
 * @param filters
 * @param preservePreviousEvents
 * @param includeFeatured
 * @param params
 * @returns {function(*): Promise<AxiosResponse<*>>}
 */
export const fetchEvents = (
  calendarUUID,
  from,
  to = null,
  limit = null,
  page = null,
  filters = [],
  preservePreviousEvents = false,
  includeFeatured = false,
  params = {}
) => {
  return _fetchEvents(
    "EVENTS_FETCH",
    calendarUUID,
    from,
    to,
    limit,
    page,
    filters,
    preservePreviousEvents,
    0,
    params
  );
};

/**
 *
 * @param calendarUUID
 * @param from
 * @param to
 * @param limit
 * @param page
 * @param filters
 * @param preservePreviousEvents
 * @param params
 * @returns {function(*): Promise<AxiosResponse<*>>}
 */
export const fetchFeaturedEvents = (
  calendarUUID,
  from,
  to = null,
  limit = null,
  page = null,
  filters = [],
  preservePreviousEvents = false,
  params = {}
) => {
  return _fetchEvents(
    "FEATURED_EVENTS_FETCH",
    calendarUUID,
    from,
    to,
    limit,
    page,
    filters,
    preservePreviousEvents,
    1,
    params
  );
};

/**
 *
 * @returns {Function}
 */
export function resetEventsCollection() {
  return function (dispatch) {
    dispatch({ type: "EVENTS_COLLECTION_RESET" });
  };
}

/**
 *
 * @param slug
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchEvent(slug) {
  return function (dispatch) {
    dispatch({ type: "EVENTS_FETCH" });

    let url = "/api4/events/" + slug + ".json";

    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: "EVENTS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "EVENTS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param eventSlug
 * @returns {function(*): Promise<AxiosResponse<any>>}
 * @constructor
 */
export function RSVPEvent(calendarUUID, eventSlug, login, password, status) {
  return function (dispatch) {
    dispatch({ type: "EVENT_RSVP" });

    let url =
      "/api/events/register_user_to_an_event.json?uuid=" +
      calendarUUID +
      "&event_slug=" +
      eventSlug;

    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        {
          login,
          password,
          registration_status: status,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        localstorage.set("rsvp-event-" + eventSlug, status);

        dispatch({
          type: "EVENT_RSVP_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "EVENT_RSVP_REJECTED",
          payload: error,
        });
      });
  };
}

export const searchEvents = (
  calendarUUID,
  query,
  limit = null,
  page = null,
  filters = [],
  preservePreviousEvents = false
) => {
  return async (dispatch) => {
    dispatch({ type: "EVENTS_FETCH" });

    const postData = {
      calendar_uuid: calendarUUID,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      query,
    };

    const queryParams = AxiosConfig.objectToURLQuery({
      page,
      limit,
      uuid: calendarUUID,
    });

    const url = "/api4/events/search.json?" + queryParams;

    return await axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        postData,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: `EVENTS_FETCH_FULFILLED`,
          payload: response.data,
          preservePreviousEvents,
        });
      })
      .catch(function (error) {
        dispatch({
          type: `EVENTS_FETCH_REJECTED`,
          payload: error,
          preservePreviousEvents,
        });
      });
  };
};
