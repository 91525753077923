import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import WeekDayEvents from "./WeekDayEvents";
import { formatDate, isSameDay } from "../../../../helpers/date";

/**
 *
 * @param {Object} calendar
 * @param {Array} events
 * @param {Object} day
 * @param {boolean} isSelected
 * @param {boolean} showEventDetails
 * @param {"events_list"|"pills"|"stack"} eventsLocation
 * @param {boolean} useEventAccent
 * @param {boolean} showEventPresenseIndication
 * @param {function} onSelectDay
 * @param {boolean} showRecurring
 * @param {boolean} showFeatured
 * @param {boolean} embedded
 * @returns {Element}
 * @constructor
 */
const WeekDay = ({
  calendar,
  events,
  day,
  isSelected,
  showEventDetails,
  eventsLocation,
  useEventAccent,
  showEventPresenseIndication,
  onSelectDay,
  showRecurring,
  showFeatured,
  embedded,
}) => {
  const onClickDay = () => {
    if (!showEventDetails) {
      onSelectDay(day);
    }
  };

  const dayEvents = useMemo(
    () =>
      events.collection.filter((e) => {
        return (
          isSameDay(day, new Date(e.start_time * 1000)) ||
          isSameDay(day, new Date(e.end_time * 1000)) ||
          (day > new Date(e.start_time * 1000) &&
            day < new Date(e.end_time * 1000))
        );
      }),
    [events, day]
  );

  const isCurrentDay = isSameDay(new Date(), day);

  const hasEventsIndication =
    dayEvents.length && !showEventDetails && showEventPresenseIndication ? (
      <div className={"weekly-cal--day-events-indicator-container"}>
        <div className={"weekly-cal--day-events-indicator"}></div>
      </div>
    ) : null;

  const eventDetails =
    showEventDetails && dayEvents.length ? (
      <WeekDayEvents
        events={dayEvents}
        day={day}
        useEventAccent={useEventAccent}
        isPills={eventsLocation === "pills"}
        isStack={eventsLocation === "stack"}
        showRecurring={showRecurring}
        showFeatured={showFeatured}
        calendarSlug={calendar.model.slug}
        embedded={embedded}
      />
    ) : null;

  const isNewMonth = day.getDate() === 1;
  const newMonth = isNewMonth ? (
    <span className={"new-month-label"}>
      {formatDate(day, { month: "short" })}
    </span>
  ) : null;

  return (
    <div className={"weekly-cal--day-wrapper"}>
      <div
        className={classnames("weekly-cal--day-container", {
          today: isCurrentDay,
          selected: isSelected,
          "new-month": isNewMonth,
        })}
        onClick={onClickDay}
      >
        {newMonth}
        <div className={"weekly-cal--day-header"}>
          {formatDate(day, { weekday: "short" })}
        </div>
        <div className={"weekly-cal--day-content"}>
          <div className={"weekly-cal--day-name"}>
            {formatDate(day, { day: "numeric" })}
          </div>
          {hasEventsIndication}
        </div>
      </div>
      {eventDetails}
    </div>
  );
};

export default WeekDay;
