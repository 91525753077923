/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2023.
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { getPlanSubscriptionName } from "../../../../helpers/subscription";
import { UserRoles } from "../../consts/crm";
import { Link } from "react-router-dom";
import appConfig from "../../../../appConfig";
import HamburgerDropdown from "../../component/UI/HamburgerDropdown";

const TableRow = (props) => {
  /**
   *
   * @returns {function(*, *): boolean}
   */
  const onUpdateOrganization = (callback) => (e) => {
    e.preventDefault();

    callback(props.organization);

    return false;
  };

  const {
    uuid,
    name,
    treasurer_email,
    installation_fee_paid,
    organizations_plans,
  } = props.organization;

  const disabled = props.disabled;

  const feePaid =
    Number.parseInt(installation_fee_paid) > 0
      ? moment(installation_fee_paid * 1000).format("YYYY-MM-DD")
      : null;
  const currentPlan =
    organizations_plans && organizations_plans.length
      ? organizations_plans[0]
      : null;

  const plan = getPlanSubscriptionName(currentPlan);
  const planExpiry =
    currentPlan && currentPlan.expiry != null
      ? moment(currentPlan.expiry * 1000).format("YYYY-MM-DD")
      : null;
  let rowBgClass = "";

  /**
   * plan expiration period (available == 1 && expiry > now())
   * plan expired (available 0)
   * plan terminated (is_terminated == 1)
   * plan trial (trial = 1)
   * plan trial expiration period (trial = 1...)
   */

  if (plan) {
    if (Number.parseInt(currentPlan.available) === 1) {
      // plan expired yet still available (due to cancel for unpaid subscription)

      if (Number.parseInt(currentPlan.is_trial) === 1) {
        rowBgClass = "table-info";
      }

      if (Number.parseInt(currentPlan.expiry) < moment().unix()) {
        rowBgClass = "table-secondary";
      } else {
        rowBgClass = "table-success";
      }

      if (Number.parseInt(currentPlan.is_terminated) === 1) {
        rowBgClass = "table-warning";
      }
    } else {
      if (Number.parseInt(currentPlan.is_terminated) === 1) {
        rowBgClass = "table-danger";
      }
    }
  }

  const hasBrandedPlan =
    currentPlan && currentPlan.subscription_plan.is_branded * 1;

  const ownerAccount = useMemo(() => {
    const owner = props.organization.users.find(
      (user) => user.role === UserRoles.owner
    );

    if (!owner) {
      return {
        name: "N/A",
        email: "N/A",
      };
    }

    return owner;
  }, [props.organization]);

  const options = useMemo(() => {
    const options = [
      {
        label: "Auto login as owner",
        icon: "login",
        onClick: onUpdateOrganization(props.onAutoLogin),
      },
      {
        label: "Edit details",
        icon: "assignment_ind",
        href: `/church/edit/${uuid}`,
      },
      {
        label: "Terminate subscription",
        icon: "cancel",
        onClick: onUpdateOrganization(props.onTerminateSubscription),
      },
      {
        label: "Delete",
        icon: "delete",
        onClick: onUpdateOrganization(props.onDelete),
      },
    ];

    return options;
  }, [
    props.organization,
    props.onAutoLogin,
    props.onDelete,
    props.onTerminateSubscription,
  ]);

  return (
    <tr className={rowBgClass}>
      <td>{name}</td>
      <td>{treasurer_email}</td>
      <td>{props.organization.users.length}</td>
      <td>
        {ownerAccount.name}
        <br />
        <span className={"small"}>({ownerAccount.email})</span>
      </td>
      <td>
        <small>{feePaid}</small>
      </td>
      <td>
        {hasBrandedPlan ? "© " : ""} {plan}{" "}
        {currentPlan
          ? Number.parseInt(currentPlan.is_annual)
            ? "Yearly"
            : ""
          : ""}
      </td>
      <td>{planExpiry}</td>

      <td className="text-right">
        <div className="dropdown dropdown-action">
          <a
            href={!disabled ? "#" : null}
            data-toggle={!disabled ? "dropdown" : ""}
            className="action-icon dropdown-toggle"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <a
              className="dropdown-item"
              href="#"
              title="Auto login"
              data-toggle="modal"
              data-target="#edit_leavetype"
              onClick={onUpdateOrganization(props.onAutoLogin)}
            >
              <i className="fa fa-magic m-r-5" /> Auto login as owner
            </a>
            <Link
              className="dropdown-item"
              to={`/${appConfig.links.organization}/edit/${uuid}`}
              title="Edit organization"
            >
              <i className="fa fa-pencil m-r-5" /> Edit details
            </Link>
            <a
              className="dropdown-item"
              href="#"
              onClick={onUpdateOrganization(props.onTerminateSubscription)}
            >
              <i className="fa fa-ban m-r-5" /> Terminate subscription
            </a>
            <a
              className="dropdown-item"
              href="#"
              title="Delete"
              data-toggle="modal"
              data-target="#delete_leavetype"
              onClick={onUpdateOrganization(props.onDelete)}
            >
              <i className="fa fa-trash-o m-r-5" /> Delete
            </a>
          </div>
        </div>
        <HamburgerDropdown disabled={disabled} options={options} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  organization: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    treasurer_email: PropTypes.string.isRequired,
    created: PropTypes.number.isRequired,
  }),

  onDelete: PropTypes.func.isRequired,
  onTerminateSubscription: PropTypes.func.isRequired,
  onAutoLogin: PropTypes.func.isRequired,
};

export default TableRow;
