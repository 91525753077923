/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { CT, IT } from "../../../consts";

const allTemplatesButDetailedList = [
  CT.card,
  CT.cardList,
  CT.cardSlider,
  CT.weekly,
  CT.monthly,
  CT.eventList,
];
export const definitions = {
  title: (
    <span>
      Search <span className={"badge badge-success"}>NEW</span>
    </span>
  ),
  icon: "search",
  groups: [
    {
      label: "Behavior",
      options: [
        {
          title: "Enable search",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
            CT.detailedList,
          ],
          name: "searchEnabled",
          defaultValue: 0,
          show: [],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Keep search expanded",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
            CT.detailedList,
          ],
          name: "searchExpandedDefault",
          defaultValue: 0,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Placeholder",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
            CT.detailedList,
          ],
          name: "searchInputPlaceholder",
          defaultValue: "Search events",
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "text",
            options: {},
          },
        },
      ],
    },
    {
      label: "Search toggle button",
      options: [
        {
          title: "Background color",
          templates: allTemplatesButDetailedList,
          name: "searchToggleBg",
          defaultValue: "#D3D3D3FF",
          style: {
            selector: [".rev-cal-expand-search-block-btn"],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Background color hover",
          templates: allTemplatesButDetailedList,
          name: "searchToggleBgHover",
          defaultValue: "#D3D3D3C1",
          style: {
            selector: [".rev-cal-expand-search-block-btn:hover"],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Background color when expanded",
          templates: allTemplatesButDetailedList,
          name: "searchToggleBgExpanded",
          defaultValue: "#D3D3D3C1",
          style: {
            selector: [
              ".rev-cal-search-block.rev-cal-search-block_expanded .rev-cal-expand-search-block-btn",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Icon color",
          templates: allTemplatesButDetailedList,
          name: "searchIconColor",
          defaultValue: "#FFF",
          style: {
            selector: [".rev-cal-expand-search-block-btn"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Icon color hover",
          templates: allTemplatesButDetailedList,
          name: "searchIconColorHover",
          defaultValue: "#FFF",
          style: {
            selector: [".rev-cal-expand-search-block-btn:hover"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Icon color when expanded",
          templates: allTemplatesButDetailedList,
          name: "searchIconColorExpanded",
          defaultValue: "#FFF",
          style: {
            selector: [
              ".rev-cal-search-block.rev-cal-search-block_expanded .rev-cal-expand-search-block-btn",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
      ],
    },
    // DETAILED LIST
    {
      label: "Search toggle button",
      options: [
        {
          title: "Background color",
          templates: [CT.detailedList],
          name: "searchToggleBg",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-search-block-btn",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
              showTransparent: true,
            },
          },
        },
        {
          title: "Background color hover",
          templates: [CT.detailedList],
          name: "searchToggleBgHover",
          defaultValue: "#d3e5f6",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-search-block-btn:hover",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Background color when expanded",
          templates: [CT.detailedList],
          name: "searchToggleBgExpanded",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block.rev-cal-search-block_expanded .rev-cal-expand-search-block-btn",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
              showTransparent: true,
            },
          },
        },
        {
          title: "Icon color",
          templates: [CT.detailedList],
          name: "searchIconColor",
          defaultValue: "#2D3748",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-search-block-btn",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Icon color hover",
          templates: [CT.detailedList],
          name: "searchIconColorHover",
          defaultValue: "#2D3748",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-search-block-btn:hover",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Icon color when expanded",
          templates: [CT.detailedList],
          name: "searchIconColorExpanded",
          defaultValue: "#2D3748",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block.rev-cal-search-block_expanded .rev-cal-expand-search-block-btn",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
      ],
    },
    {
      label: "Text input",
      options: [
        {
          title: "Background color",
          templates: allTemplatesButDetailedList,
          name: "searchInputBg",
          defaultValue: "#FFF",
          style: {
            selector: [".rev-cal-search-block-input"],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Text color",
          templates: allTemplatesButDetailedList,
          name: "searchInputTextColor",
          defaultValue: "#333",
          style: {
            selector: [".rev-cal-search-block-input"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Text color placeholder",
          templates: allTemplatesButDetailedList,
          name: "searchInputTextColorPlaceholder",
          defaultValue: "#8c8c8c",
          style: {
            selector: [".rev-cal-search-block input::placeholder"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },

        {
          title: "Clear button background color",
          templates: allTemplatesButDetailedList,
          name: "searchInputClearBg",
          defaultValue: "#ddd",
          style: {
            selector: [".rev-cal-expand-clear-block-btn"],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Clear button background color hover",
          templates: allTemplatesButDetailedList,
          name: "searchInputClearBgHover",
          defaultValue: "#ddd",
          style: {
            selector: [".rev-cal-expand-clear-block-btn:hover"],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Clear button icon color",
          templates: allTemplatesButDetailedList,
          name: "searchInputClearColor",
          defaultValue: "#333",
          style: {
            selector: [".rev-cal-expand-clear-block-btn"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Clear button icon color hover",
          templates: allTemplatesButDetailedList,
          name: "searchInputClearColorHover",
          defaultValue: "#333",
          style: {
            selector: [".rev-cal-expand-clear-block-btn:hover"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Input border",
          templates: allTemplatesButDetailedList,
          name: "searchInputBorder",
          defaultValue: "#D3D3D3C1",
          style: {
            selector: [
              ".rev-cal-expand-search-block-btn",
              ".rev-cal-search-block-input",
              ".rev-cal-search-block-btn",
            ],

            property: "border-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
      ],
    },
    // DETAILED LIST
    {
      label: "Text input",
      options: [
        {
          title: "Background color",
          templates: [CT.detailedList],
          name: "searchInputBg",
          defaultValue: "#FFF",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block-input",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Text color",
          templates: [CT.detailedList],
          name: "searchInputTextColor",
          defaultValue: "#2d3748",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block-input",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Text color placeholder",
          templates: [CT.detailedList],
          name: "searchInputTextColorPlaceholder",
          defaultValue: "#8c8c8c",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block input::placeholder",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },

        {
          title: "Clear button background color",
          templates: [CT.detailedList],
          name: "searchInputClearBg",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-clear-block-btn",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Clear button background color hover",
          templates: [CT.detailedList],
          name: "searchInputClearBgHover",
          defaultValue: "#edf2f7",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-clear-block-btn:hover",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Clear button icon color",
          templates: [CT.detailedList],
          name: "searchInputClearColor",
          defaultValue: "#333",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-clear-block-btn",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Clear button icon color hover",
          templates: [CT.detailedList],
          name: "searchInputClearColorHover",
          defaultValue: "#333",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-clear-block-btn:hover",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
        {
          title: "Input border",
          templates: [CT.detailedList],
          name: "searchInputBorder",
          defaultValue: "#d5d5d5",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-expand-search-block-btn",
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block-input",
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block-btn",
            ],

            property: "border-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
            },
          },
        },
      ],
    },
    {
      label: "Submit button",
      options: [
        {
          title: "Background color",
          templates: allTemplatesButDetailedList,
          name: "searchSubmitBg",
          defaultValue: "#D3D3D3FF",
          style: {
            selector: [".rev-cal-search-block-btn"],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {},
          },
        },
        {
          title: "Background color hover",
          templates: allTemplatesButDetailedList,
          name: "searchSubmitBgHover",
          defaultValue: "#D3D3D3C1",
          style: {
            selector: [".rev-cal-search-block-btn:hover"],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {},
          },
        },
        {
          title: "Text color",
          templates: allTemplatesButDetailedList,
          name: "searchSubmitTextColor",
          defaultValue: "#333",
          style: {
            selector: [".rev-cal-search-block-btn"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {},
          },
        },
        {
          title: "Text color hover",
          templates: allTemplatesButDetailedList,
          name: "searchSubmitTextColorHover",
          defaultValue: "#333",
          style: {
            selector: [".rev-cal-search-block-btn:hover"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {},
          },
        },
      ],
    },
    // DETAILED LIST
    {
      label: "Submit button",
      options: [
        {
          title: "Background color",
          templates: [CT.detailedList],
          name: "searchSubmitBg",
          defaultValue: "#EDF2F7FF",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block-btn",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {},
          },
        },
        {
          title: "Background color hover",
          templates: [CT.detailedList],
          name: "searchSubmitBgHover",
          defaultValue: "#d3e5f6",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block-btn:hover",
            ],
            property: "background-color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {},
          },
        },
        {
          title: "Text color",
          templates: [CT.detailedList],
          name: "searchSubmitTextColor",
          defaultValue: "#333",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block-btn",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {},
          },
        },
        {
          title: "Text color hover",
          templates: [CT.detailedList],
          name: "searchSubmitTextColorHover",
          defaultValue: "#333",
          style: {
            selector: [
              ".dc-detailed-list--calendar-header-top-controls .rev-cal-search-block-btn:hover",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "searchEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {},
          },
        },
      ],
    },
  ],
};
