/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/11/2024
 */

import React, { useContext, useReducer, useState } from "react";
import RowModal from "./EventsList/RowModal";
import { useCalendarStore } from "../../../hooks/redux/calendar";
import CalendarLinkingContext from "../../../contexts/CalendarLinkingContext";
import useQuery from "../../../hooks/useQuery";
import useSubscribePopup from "../hooks/useSubscribePopup";

/**
 *
 * @param {Boolean} embedded - Whetner it's embedded in the website/app or used in the builder
 * @returns {Element}
 * @constructor
 */
const SelectedEventModal = ({ embedded, onRSVPEvent }) => {
  const { calendar } = useCalendarStore();
  const {
    selectedEvent,
    showDetailsAsModal,
    expandDetailsAsAccordion,
    showHighlightsDetailsAsModalValue,
  } = useContext(CalendarLinkingContext);

  const query = useQuery();
  const selectorToScroll = query.has("selector") ? query.get("selector") : null;
  const isHighlight = query.has("highlights");
  const { subscribePopup } = useSubscribePopup({
    eventName: selectedEvent?.name,
    integrationUUID: calendar.model.integration.uuid,
    widgetUUID: calendar.model.uuid,
    ICSID: selectedEvent?.ics_id,
  });

  const modalEvent =
    (selectedEvent && showDetailsAsModal) ||
    (selectedEvent && isHighlight && showHighlightsDetailsAsModalValue) ? (
      <>
        {subscribePopup}
        <RowModal
          event={selectedEvent}
          showDetails={true}
          calendarSlug={calendar.model.slug}
          embedded={embedded}
          ccbAddress={calendar.model.integration.address}
          onRSVPEvent={onRSVPEvent}
          loading={
            calendar.rsvp ||
            calendar.fetch ||
            calendar.delete ||
            calendar.update
          }
          error={calendar.rsvpError}
          expandedRowSlug={selectedEvent.slug}
          expandAsAccordion={expandDetailsAsAccordion}
          expandAsModal={showDetailsAsModal}
          calendarID={`dce_calendar__${calendar.model.uuid}`}
          showWatermark={calendar?.model?.is_watermarked}
          onOpenScrollToSelector={selectorToScroll}
          calendarUUID={calendar.model.uuid}
        />
      </>
    ) : null;

  return modalEvent;
};

export default SelectedEventModal;
