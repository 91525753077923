/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/11/2022
 */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { store } from "./../../store";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import isEmail from "validator/lib/isEmail";
import { updateJquery } from "./../../tools";

import ReCAPTCHA from "react-google-recaptcha";
import { useUserStore } from "./../../../../hooks/redux/user";
import { useAppStore } from "./../../../../hooks/redux/app";
import useAuthHook from "./../../hooks/useAuthHook";
import { useTranslation } from "react-i18next";
import useRedirectUrl from "../../hooks/useRedirectUrl";

const EmailSignUpForm = (props) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    organizationName:
      (props.formValues && props.formValues.organizationName) ?? "",
    email: (props.formValues && props.formValues.email) ?? "",
    password: "",
    captchaToken: "",
    coupon: "",
    organization_uuid:
      (props.formValues && props.formValues.organization_uuid) ?? null,

    firstNameError: null,
    lastNameError: null,
    organizationNameError: null,
    emailError: null,
    passwordError: null,
    captchaError: null,

    hasCoupon: false,
  });

  const { t } = useTranslation();

  const history = useHistory();
  const { clearErrors } = useAppStore();
  const { user, register, setPluginLinkRedirect } = useUserStore();
  const { isLoading: authLoading, authError, onGoogleSignUp } = useAuthHook();
  const captchaRef = useRef(null);
  const { redirectOrNavigateTo, redirectUrl } = useRedirectUrl();

  /**
   *
   * @param field
   * @returns {function(...[*]=)}
   */
  const setValue = (field) => (e) => {
    const newState = { ...state };
    newState[field] = e.target.value;
    setState(newState);
  };

  const firstNameRef = useRef();
  useEffect(() => {
    updateJquery();

    firstNameRef.current.focus();
    return clearErrors;
  }, []);

  useEffect(() => {
    if (state.hasCoupon) {
      updateJquery();

      document.getElementById("invite_code").focus();
    }
  }, [state.hasCoupon]);

  useEffect(() => {
    if (user.registerError && captchaRef.current) {
      captchaRef.current.reset();
    }
  }, [user.registerError, captchaRef.current]);

  const onCaptchaTokenSet = (e) => {
    setState({
      ...state,
      captchaToken: e,
      captchaError: null,
    });
  };

  /**
   *
   */
  const onRegisterClick = async (e) => {
    e.preventDefault();

    const { firstName, lastName, email, password, coupon, organization_uuid } =
      state;

    let emailError = null;
    let passwordError = null;
    let firstNameError = null;
    let lastNameError = null;
    let captchaError = null;
    const enforceEmail = document.querySelector(
      "input[name=ensure_email_correct]"
    )?.checked;

    if (!isEmail(email)) {
      emailError = "Please enter a valid email address. ";
    }

    if (!firstName || firstName.length < 2) {
      firstNameError = "Please enter your first name. ";
    }

    if (!lastName || lastName.length < 2) {
      lastNameError = "Please enter your last name. ";
    }

    if (!password || password.length < 5) {
      passwordError = "Please enter a password, at least 5 characters. ";
    }

    if (!state.captchaToken) {
      captchaError = "Confirm you are not a robot";
    }

    setState({
      ...state,
      firstNameError,
      lastNameError,
      passwordError,
      emailError,
      captchaError,
    });

    if (
      !firstNameError &&
      !lastNameError &&
      !passwordError &&
      !emailError &&
      !captchaError
    ) {
      await register({
        first_name: firstName,
        last_name: lastName,
        organization_name: state.organizationName,
        email,
        password,
        trial_coupon: coupon.toUpperCase(),
        captcha_token: state.captchaToken,
        organization_uuid,
        enforce_email: enforceEmail,
      });

      if (store.getState().user.registerSuccess) {
        redirectOrNavigateTo("/dashboard");
      }
    }

    return false;
  };

  const onHaveInvitationCode = (e) => {
    e.preventDefault();
    setState({ ...state, hasCoupon: true });
  };

  const haveACouponLink =
    !state.hasCoupon && props.displayCouponLink ? (
      <div className="text-sm mb-3">
        <Link
          to={"#"}
          className={"text-xs card-link text-primary"}
          style={{ fontSize: "0.9em" }}
          onClick={onHaveInvitationCode}
        >
          Do you have an invite code?
        </Link>
      </div>
    ) : null;

  const isLoading = user.register || authLoading;

  const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

  const couponInput = state.hasCoupon ? (
    <div className="form-group form-focus">
      <label className="focus-label">Enter your invite code (optional)</label>
      <input
        id={"invite_code"}
        className={"form-control floating "}
        type="text"
        value={state.coupon}
        onChange={setValue("coupon")}
        disabled={isLoading}
      />
    </div>
  ) : null;

  const passwordInputStyle = useMemo(
    () => (state.hasCoupon ? {} : { marginBottom: "5px" }),
    [state.hasCoupon]
  );

  const submitBtnText = props.submitBtnText ?? "START FREE TRIAL";

  const hasSubmittedEmailError = useMemo(() => {
    return (
      user.registerError &&
      user.registerError?.response?.data?.error?.find(
        (i) =>
          i.field === "email" &&
          i.messages[0]?.toLowerCase().includes("did you mean")
      )
    );
  }, [user.registerError]);

  const ensureCorrectEmail = useMemo(() => {
    return hasSubmittedEmailError ? (
      <div className={"form-group"}>
        <div className="checkbox">
          <label>
            <input type="checkbox" name="ensure_email_correct" /> This email is
            correct.
          </label>
        </div>
      </div>
    ) : null;
  }, [hasSubmittedEmailError]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onSignupWithGoogleClick = useCallback(
    (e) => {
      if (isLoading) {
        return false;
      }

      if (redirectUrl) {
        // ...
        // store the redirect url in the user's store. When user comes back from Google oAuth then they need
        // to follow the registration flow (setting up the integration, syncing their calendar, etc).
        // once they are done with that, they would get an additional step in the dashboard called "Complete WordPress Connection Setup"
        setPluginLinkRedirect(redirectUrl);
      }

      onGoogleSignUp(
        "register" +
          (props.formValues && props.formValues.invitationHash
            ? "&invitationHash=" + props.formValues.invitationHash
            : "")
      )(e);
    },
    [isLoading, redirectUrl]
  );

  return (
    <form action="/">
      <div className="form-group  form-focus">
        <label className="focus-label">First name</label>

        <input
          className={classnames("form-control floating ", {
            "is-invalid": state.firstNameError,
          })}
          type="text"
          value={state.firstName}
          ref={firstNameRef}
          onChange={setValue("firstName")}
          disabled={isLoading}
        />
      </div>
      <div className="form-group form-focus">
        <label className="focus-label">Last name</label>

        <input
          className={classnames("form-control floating ", {
            "is-invalid": state.lastNameError,
          })}
          type="text"
          value={state.lastName}
          autoFocus={true}
          onChange={setValue("lastName")}
          disabled={isLoading}
        />
      </div>

      <div className="form-group form-focus">
        <label className="focus-label">{t("Organization")} name</label>

        <input
          className={classnames("form-control floating ", {
            "is-invalid": state.organizationNameError,
          })}
          type="text"
          value={state.organizationName}
          autoFocus={true}
          onChange={setValue("organizationName")}
          disabled={
            isLoading ||
            (props.disabledFields && props.disabledFields.organizationName)
          }
        />
      </div>

      <div
        className={classnames("form-group form-focus", {
          "m-b-0": hasSubmittedEmailError,
        })}
      >
        <label className="focus-label">Email</label>

        <input
          className={classnames("form-control floating", {
            "is-invalid": state.emailError || hasSubmittedEmailError,
          })}
          type="email"
          value={state.email}
          autoFocus={true}
          onChange={setValue("email")}
          disabled={
            isLoading || (props.disabledFields && props.disabledFields.email)
          }
        />
      </div>
      {ensureCorrectEmail}

      <div className="form-group form-focus" style={passwordInputStyle}>
        <label className="focus-label">Password</label>
        <input
          className={
            "form-control floating " + (state.passwordError ? "is-invalid" : "")
          }
          type="password"
          value={state.password}
          onChange={setValue("password")}
          disabled={isLoading}
        />
      </div>
      {haveACouponLink}

      {couponInput}
      <div className="form-group">
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={process.env.RECAPTCHA_SITE_KEY}
          onChange={onCaptchaTokenSet}
        />
      </div>
      <div className="form-group text-center">
        <button
          className="btn btn-primary btn-block account-btn"
          type="submit"
          onClick={onRegisterClick}
          disabled={isLoading}
        >
          {submitBtnText}
        </button>
      </div>

      <div className={"d-block w-100"}>
        <h4 className={"or "}>or</h4>
      </div>
      <div className="form-group text-center">
        <img
          style={{ width: "250px" }}
          className={"btn "}
          onClick={onSignupWithGoogleClick}
          disabled={isLoading}
          src={"/img/btn_google_signin_light_normal_web@2x.png"}
        />
      </div>

      <ApiError
        error={user.registerError}
        heading={"Could not create the account:"}
        defaultErrorMessage={"Could not create an account."}
      />

      <FormError
        errors={[
          state.firstNameError,
          state.lastNameError,
          state.emailError,
          state.passwordError,
          state.captchaError,
        ]}
      />

      {loadingIndicator}
    </form>
  );
};

EmailSignUpForm.defaultProps = {
  displayCouponLink: true,
  displayOrganizationName: true,
};

EmailSignUpForm.propTypes = {
  displayCouponLink: PropTypes.bool,
  displayOrganizationName: PropTypes.bool,
};

export default EmailSignUpForm;
