/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/10/2023
 */
import AxiosConfig from "../../../../../AxiosConfig";
import appConfig from "../../../../../appConfig";

export const widgetAddress = window.__DCE__DEV__
  ? `${window.location.origin}/${appConfig.widgetPath}`
  : `https://${process.env.WIDGETS_DOMAINNAME}/${appConfig.widgetPath}`;
export const loaderScriptUrl = window.__DCE__DEV__
  ? `${widgetAddress}/loader.js`
  : `${widgetAddress}/loader.min.js`;

export const eventAlertsScriptUrl = window.__DCE__DEV__
  ? `${widgetAddress}/alerts.js`
  : `${widgetAddress}/alerts.min.js`;

export const installScripTag = `<script async id="dce-embeddable-script" src="${loaderScriptUrl}"></script>`;

export const installAlertsScripTag = `<script async id="dc-alerts-script" src="${eventAlertsScriptUrl}"></script>`;

/**
 * Get install div tag
 * @param {String} widgetType
 * @param {String} uuid
 * @param {String} [widgetTemplate] - template type, use for detecting loader indicator.
 * @returns {`<div class="dce-${string}" id="${string}" ${string}></div>`}
 */
export const getInstallDivTag = (widgetType, uuid, widgetTemplate = "") =>
  `<div class="dce-${widgetType}" id="${uuid}" data-wt="${widgetTemplate}"></div>`;

/**
 * Get install iframe tag
 * @param {String} widgetType
 * @param {String} uuid
 * @param {String} slug
 * @param {String} [widgetTemplate]
 * @returns {`<div class="dce-${string}" id="${string}" iframe="true" slug="${string}"></div>`}
 */
export const getInstallIframeTag = (widgetType, uuid, slug, widgetTemplate) =>
  `<div class="dce-${widgetType}" id="${uuid}" iframe="true" slug="${slug}" data-wt="${widgetTemplate}"></div>`;

export const wixHeadScript = `<script async id="dc-wix-embeddable-script" src="${loaderScriptUrl}" wix="true"></script>`;

/**
 *
 * @param {String} widgetType
 * @param {String} uuid
 * @param {String} widgetTemplate = "
 * @returns {String}
 */
export const getWixEmbeddedScript = (widgetType, uuid, widgetTemplate = "") => {
  // const queryParams = {
  //   min: window.__DCE__DEV__ ? false : true,
  //   uuid,
  //   widget_type: widgetType,
  // };

  return `<div class="dce-${widgetType}" id="${uuid}" data-wt="${widgetTemplate}"></div><script>!function(){var e={widgetId:"${uuid}",type:"${widgetType}",template:"${widgetTemplate}"};window.location.hostname.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)&&setTimeout((function(){var e=document.createElement("script");e.src="${loaderScriptUrl}",e.setAttribute("wix",!0),e.setAttribute("wixbuilder",!0),e.id="dc-embeddable-script";var t=document.createElement("div");t.innerHTML="INFO: Height of the widget will automatically adjust when live and this message will disappear.",t.style.backgroundColor="#fff",t.style.color="#000",t.style.position="absolute",t.style.bottom=0,document.body.appendChild(t),document.body.appendChild(e)}),10),window.addEventListener("message",(function(t){null!=t.data.advertise&&window.parent.postMessage(e,"*")}))}();</script>`;
  /*
  const wixLoaderScript =
    `${widgetAddress}/wix_loader.js?` +
    AxiosConfig.objectToURLQuery(queryParams);

  return `<div class="dce-${widgetType}" id="${uuid}"></div>
<script>
  var script = document.createElement("script");
  script.src = "${wixLoaderScript}";
  script.async = true;
  script.id = 'dc-wix-embeddable-script';
  document.body.appendChild(script);
</script>`;*/
};

/*<div className="dce-${widgetType}" id="${uuid}"></div>
<script>*/
/*(function () {
  var ifm = { widgetId: "${uuid}", type: "${widgetType}" };

  if (
    window.location.hostname.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
    )
  ) {
    setTimeout(function () {
      var script = document.createElement("script");
      script.src = "${loaderScriptUrl}";
      script.setAttribute('wix', true);
      script.setAttribute('wixbuilder', true);
      script.id = "dc-embeddable-script";

      var inf = document.createElement("div");
      inf.innerHTML =
        "INFO: Height of the widget will automatically adjust when live and this message will disappear.";
      inf.style.backgroundColor = "#fff";
      inf.style.color = "#000";
      inf.style.position = "absolute";
      inf.style.bottom = 0;
      document.body.appendChild(inf);
      document.body.appendChild(script);
    }, 10);
  }
  window.addEventListener("message", function (e) {
    if (e.data.advertise != undefined) {
      window.parent.postMessage(ifm, "*");
    }
  });
})();*/
/*</script>*/
