/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2022.
 */

import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Tos from "../../templates/PageTemplate/Tos";
import AxiosConfig from "./../../../../AxiosConfig";
import ApiError from "./../../component/ApiError";
import appConfig from "../../../../appConfig";
import useQuery from "../../../../hooks/useQuery";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import axios from "axios";
import { ucFirst } from "../../../../helpers/string";
import { useDomainsStore } from "../../../../hooks/redux/domains";
import usePluginParams from "./hooks/usePluginParams";
import ActionButtons from "./PluginsAuthPage/ActionButtons";
import { useUserStore } from "../../../../hooks/redux/user";

const PLuginNamesMapping = {
  wp: "WordPress Website",
};

const InfoLabel = ({ label, value }) => {
  if (!value) {
    return null;
  }

  return (
    <div>
      <span className={"text-secondary"}>{ucFirst(label)}</span> {value}
    </div>
  );
};
const PluginsAuthPage = (props) => {
  const params = useQuery();
  const requestData = usePluginParams(params);

  const [submitting, setSubmitting] = useState(false);
  const { domains, updateDomain } = useDomainsStore();
  const { setPluginLinkRedirect } = useUserStore();
  const isLoading = domains.fetch || domains.update || submitting;

  useEffect(() => {
    setPluginLinkRedirect(null);
  }, []);

  const { currentOrganization } = useCurrentOrganizationStore();

  const linkAccount = useCallback(() => {
    setSubmitting(true);
    axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api4/plugins/auth",
        {
          app_type: requestData.type,
          app_name: requestData.name,
        },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        // console.log(response);
        // return;
        window.location =
          requestData.callbackUrl + `&api_key=${response.data.api_key}`;
      })
      .catch((error) => {
        setSubmitting(false);
        // TODO: handle error
        console.log(error);
      });
  }, [submitting]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const hasDomain = domains.collection.find(
        (domain) => domain.domain === requestData.domain
      );

      if (!hasDomain) {
        await updateDomain(null, {
          domain: requestData.domain,
          available: 1,
        });
      } else {
        linkAccount();
      }

      // console.log("submit");

      return false;
    },
    [domains.collection, submitting]
  );

  useEffect(() => {
    if (domains.updateSuccess) {
      linkAccount();
    }
  }, [domains.updateSuccess]);

  /**
   *
   * @type {function(*): Promise<boolean>}
   */
  const onCancel = useCallback(
    async (e) => {
      e.preventDefault();

      window.location = requestData.callbackUrl + `&error=cancelled`;

      return false;
    },
    [domains.collection, submitting]
  );

  const domainsError = useMemo(() => {
    if (domains.updateError) {
      if (domains.updateError.response?.data?.error[0]?.messages) {
        console.log(domains.updateError.response.data.error[0].messages);
        if (
          domains.updateError.response.data.error[0].messages[0].includes(
            "Maximum"
          )
        ) {
          return (
            <div className={"mb-3"}>
              <div className={"alert alert-danger"}>
                You have reached the maximum number of domains allowed for your
                account.
              </div>
              <div>
                <Link to={"/billing"} className={"text-success text-bold"}>
                  Upgrade your account
                </Link>{" "}
                to add more domains or remove an existing domain to add a new
                one <Link to={"/domains"}>here</Link>.
              </div>
            </div>
          );
        }
      }
      return <ApiError error={domains.updateError} />;
    }

    return null;
  }, [domains.updateError]);

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className={"d-flex justify-content-center"}>
            <div className="card-box card-box_plugin w-40vw">
              <div className="card-title">
                <h3 className="account-header d-flex flex-column align-items-center ">
                  Authorize {PLuginNamesMapping[requestData.type]}
                  <div className={"rev-plugin-auth--icons"}>
                    <img
                      src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${requestData.url}&size=64`}
                    />
                    <span className="material-icons">link</span>
                    <img
                      src={`/img/branding/${appConfig.branding}/icon/ms-icon-310x310.png`}
                    />
                  </div>
                </h3>
              </div>
              <div className={"card-body"}>
                <p>
                  You're connecting your {PLuginNamesMapping[requestData.type]}{" "}
                  to {appConfig.displayName}. This connection allows you to
                  embed and manage widgets seamlessly on your site. Review the
                  details below and confirm the integration to get started.
                </p>
                <form action="/" onSubmit={onSubmit} onReset={onCancel}>
                  <div>
                    <h4>Website info</h4>
                    <div className={"m-b-30"}>
                      <InfoLabel label={"site name"} value={requestData.name} />
                      <InfoLabel
                        label={"admin email"}
                        value={requestData.adminEmail}
                      />
                      <InfoLabel
                        label={"version"}
                        value={requestData.version}
                      />
                      <InfoLabel label={"url"} value={requestData.url} />
                      {/*<InfoLabel*/}
                      {/*  label={"callback"}*/}
                      {/*  value={requestData.callbackUrl}*/}
                      {/*/>*/}
                    </div>
                    <div className={"m-b-30"}>
                      <h4>Account info</h4>
                      <InfoLabel
                        label={"organization name"}
                        value={currentOrganization.model.name}
                      />
                    </div>
                  </div>

                  {domainsError}
                  <ActionButtons isLoading={isLoading} />
                </form>
              </div>
            </div>
          </div>
          <div className={"d-flex justify-content-center m-b-30"}>
            <div className={"card-box_auth w-40vw d-flex gap-xs"}>
              <a href={appConfig.helpdeskAddress}>
                Helpdesk <i className={"material-icons icon-xs"}>open_in_new</i>
              </a>
              {appConfig.branding === "dc" ? (
                <a href={`https://feedback.${appConfig.domain}`}>
                  Feedback{" "}
                  <i className={"material-icons icon-xs"}>open_in_new</i>
                </a>
              ) : null}
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

export default PluginsAuthPage;
