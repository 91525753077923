const initialState = {
  model: null,
  magicLink: null,

  fetch: true,
  fetchError: null,
  fetchSuccess: false,

  fetchMagicLink: false,
  fetchMagicLinkError: null,
  fetchMagicLinkSuccess: false,

  create: false,
  createError: null,
  createSuccess: false,

  update: false,
  updateError: null,
  updateSuccess: false,

  updateLink: false,
  updateLinkError: false,
  updateLinkSuccess: false,

  updateAlertUUID: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "AFFILIATE_FETCH":
      return {
        ...state,
        fetch: true,
      };

    case "AFFILIATE_FETCH_FULFILLED":
      return {
        ...state,
        model:
          action.payload &&
          Array.isArray(action.payload) &&
          action.payload.length
            ? action.payload
            : null,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "AFFILIATE_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "AFFILIATE_CREATE":
      return {
        ...state,
        create: true,
      };

    case "AFFILIATE_CREATE_FULFILLED":
      return {
        ...state,
        model: action.payload,
        create: false,
        createSuccess: true,
        createError: false,
      };

    case "AFFILIATE_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.payload,
      };

    case "AFFILIATE_UPDATE":
      return {
        ...state,
        update: true,
      };

    case "AFFILIATE_UPDATE_FULFILLED":
      return {
        ...state,
        model: action.payload,
        update: false,
        updateSuccess: true,
        updateError: false,
      };

    case "AFFILIATE_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateSuccess: false,
        updateError: action.payload,
      };

    case "MAGIC_LINK_FETCH":
      return {
        ...state,
        fetchMagicLink: true,
      };

    case "MAGIC_LINK_FETCH_FULFILLED":
      return {
        ...state,
        magicLink: action.payload,
        fetchMagicLink: false,
        fetchMagicLinkSuccess: true,
        fetchMagicLinkError: false,
      };

    case "MAGIC_LINK_FETCH_REJECTED":
      return {
        ...state,
        fetchMagicLink: false,
        fetchMagicLinkSuccess: false,
        fetchMagicLinkError: action.payload,
      };

    case "MAGIC_LINK_RESET":
      return {
        ...state,
        magicLink: null,
        fetchMagicLink: false,
        fetchMagicLinkSuccess: false,
        fetchMagicLinkError: null,
      };

    case "AFFILIATE_LINK_UPDATE":
      return {
        ...state,
        updateLink: true,
      };

    case "AFFILIATE_LINK_UPDATE_FULFILLED":
      return {
        ...state,
        model: action.payload,
        updateLink: false,
        updateLinkSuccess: true,
        updateLinkError: false,
      };

    case "AFFILIATE_LINK_UPDATE_REJECTED":
      return {
        ...state,
        updateLink: false,
        updateLinkSuccess: false,
        updateLinkError: action.payload,
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: {} };
    default:
      return state;
  }

  return state;
}
