/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2022
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ApiError from "./../../component/ApiError";

import useQuery from "../../../../hooks/useQuery";
import { useUserStore } from "./../../../../hooks/redux/user";
import { updateJquery } from "./../../tools";
import { useAppStore } from "./../../../../hooks/redux/app";
import { Link, useHistory } from "react-router-dom";
import useAuthHook from "./../../hooks/useAuthHook";
import useRedirectUrl from "../../hooks/useRedirectUrl";

const LoginEmailForm = (props) => {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const { isLoading: authLoading, authError, onGoogleSignUp } = useAuthHook();

  const { user, login } = useUserStore();
  const { clearErrors } = useAppStore();
  const emailRef = useRef();
  const { redirectOrNavigateTo, redirectUrl } = useRedirectUrl();

  console.log("redirectUrl", redirectUrl);

  useEffect(() => {
    updateJquery();
    setTimeout(() => emailRef.current.focus(), 100);

    return clearErrors;
  }, []);

  useEffect(() => {
    if (user.loginSuccess) {
      redirectOrNavigateTo("/dashboard");
    }
  }, [user.loginSuccess]);

  /**
   *
   */
  const onLoginClick = useCallback(
    async (e) => {
      e.preventDefault();

      await login(state.email, state.password);

      return false;
    },
    [state]
  );

  /**
   *
   * @param field
   * @returns {function(...[*]=)}
   */
  const onSetInputValue = useCallback(
    (field) => (e) => {
      const data = { ...state };

      data[field] = e.target.value;
      setState(data);
    },
    [state]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const onSignupWithGoogleClick = useCallback(
    (e) => {
      let flowParam = "login";
      if (redirectUrl) {
        console.log("adfda", redirectUrl);
        flowParam += "&redirect=" + btoa(encodeURIComponent(redirectUrl));
      }
      console.log(flowParam, redirectUrl);
      onGoogleSignUp(flowParam)(e);
    },
    [redirectUrl]
  );

  const isLoading = user.login || authLoading;

  return (
    <form action="/">
      <div className="form-group form-focus">
        <label className="focus-label">Email</label>

        <input
          className="form-control floating"
          type="email"
          value={state.email}
          ref={emailRef}
          onChange={onSetInputValue("email")}
          disabled={isLoading}
        />
      </div>
      <div className="form-group form-focus">
        <label className="focus-label">Password</label>
        <input
          className="form-control floating"
          type="password"
          value={state.password}
          onChange={onSetInputValue("password")}
          disabled={isLoading}
        />
      </div>
      <div className="form-group text-center">
        <button
          className="btn btn-primary btn-block account-btn"
          type="submit"
          onClick={onLoginClick}
          disabled={isLoading}
        >
          Login
        </button>

        <div className={"mt-2"}>
          <Link to="/password-reminder">Forgot your password?</Link>
        </div>
      </div>
      <ApiError
        error={user.loginError}
        defaultErrorMessage={"Invalid email or password."}
      />

      <div className={"d-block w-100"}>
        <h4 className={"or "}>or</h4>
      </div>
      <div className="form-group text-center">
        <img
          style={{ width: "250px" }}
          className={"btn "}
          onClick={onSignupWithGoogleClick}
          disabled={isLoading}
          src={"/img/btn_google_signin_light_normal_web@2x.png"}
        />
      </div>

      <ApiError error={authError} />
    </form>
  );
};

LoginEmailForm.defaultProps = {};

LoginEmailForm.propTypes = {};

export default LoginEmailForm;
