/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/01/2025.
 */

import React, { useCallback, useMemo } from "react";
import { WT } from "../../consts/widget";
import classNames from "classnames";
import {
  useSortableTables,
  useSortableTablesStore,
} from "../../../../hooks/redux/sortableTables";

const SortOrders = [null, "asc", "desc"];

const SortOrderIcons = {
  asc: "arrow_upward",
  desc: "arrow_downward",
};

/**
 *
 * @param {Array} columns
 * @param {String} columns[].id id of the column
 * @param {String} columns[].label printable label
 * @param {String} [columns[].sort] optional - a sorting function that will receive {String="asc","desc"} order parameter and return a function that will sort the data
 * @param {String} [columns[].minWidth] optional - a minimal width of the column
 * @param {Boolean} [columns[].show] optional - whether to show column or not
 * @param [Array] rows pre-rendered array of React elements that represent the rows
 * @param {String} widgetType
 * @constructor
 */
const SortableTable = ({ columns, rows, widgetType }) => {
  const { sortableTables, setWidgetsSorting } = useSortableTablesStore();
  /**
   *
   * @type {function(*): function(*): void}
   */
  const onHeaderClick = useCallback(
    (columnId) => (e) => {
      const column = columns.find((column) => column.id === columnId);

      if (!column.sort) {
        return;
      }

      if (sortableTables.columns[widgetType].field !== columnId) {
        setWidgetsSorting(widgetType, columnId, "asc");
      } else {
        setWidgetsSorting(
          widgetType,
          columnId,
          SortOrders[
            (SortOrders.indexOf(sortableTables.columns[widgetType].order) + 1) %
              SortOrders.length
          ]
        );
      }
    },
    [sortableTables.columns]
  );

  return (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          {columns.map((column) => {
            if (column.show === false) {
              return null;
            }

            const sortIcon = column.sort ? (
              <i className="material-icons icon-sm">
                {sortableTables.columns[widgetType].field === column.id &&
                sortableTables.columns[widgetType].order
                  ? sortableTables.columns[widgetType].order === "asc"
                    ? "arrow_upward"
                    : "arrow_downward"
                  : "unfold_more"}
              </i>
            ) : null;

            return (
              <th
                key={column.id}
                width={column.minWidth}
                className={classNames(column.className || "", {
                  "cursor-pointer": column.sort,
                })}
                onClick={onHeaderClick(column.id)}
              >
                {sortIcon ? (
                  <div className={"d-flex"}>
                    {sortIcon}
                    {column.label}
                  </div>
                ) : (
                  column.label
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default SortableTable;
